@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ archive-joblist
// ============================================================

//  @ archive-heading01
// ----------------------------------------

.archive-heading01 {
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px #BBBBBB solid;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 20px;
    padding-bottom: 7px;
  }

  &__title {
    color: var.$brand-main-darker;
    font-size: 18px;
    @include mix.max-media(var.$screen-md){
      font-size: 16px;
    }
  }

  &__num {
    display: flex;

    p {
      color: var.$brand-main-darker;
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      @include mix.max-media(var.$screen-md){
        font-size: 16px;
      }

      span {
        margin-right: 4px;
        font-family: var.$font-family-roboto;
        font-size: 30px;
        @include mix.max-media(var.$screen-md){
          font-size: 26px;
        }
      }
    }
  }
}

//  @ archive-joblist-list
// ----------------------------------------

.archive-joblist-list {
  display: flex;
  flex-flow: row wrap;
  gap: 40px 20px;

  @include mix.max-media(var.$screen-mlg){
    display: block;
  }

  &__item {
    width: calc(50% - 20px / 2);

    @include mix.max-media(var.$screen-mlg){
      width: 100%;
    }

    &:not(:last-child){
      @include mix.max-media(var.$screen-mlg) {
        margin-bottom: 40px;
      }
      @include mix.max-media(var.$screen-md){
        margin-bottom: 30px;
      }
    }

    > a {
      margin-bottom: 17px;
      display: block;
      text-decoration: none;
      color: var.$brand-main-darker;
      @include mix.text-hover();
      @include mix.max-media(var.$screen-md){
        margin-bottom: 7px;
      }
    }
  }

  &__thumb {
    margin-bottom: 17px;
    padding-top: 42.69%;
    width: 100%;
    position: relative;
    overflow: hidden;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 12px;
      padding-top: 47.76%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  &__title {
    font-size: 20px;
    line-height: 1.4;
    @include mix.max-media(var.$screen-md){
      font-size: 16px;
    }
  }

  &__tag-wrap {
    margin: 0 -5px;
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /*「Google Chrome」「Safari」「Microsoft Edge」対応のスクロールバー非表示コード*/
    }

    @include mix.max-media(var.$screen-md){
      margin: 0 -4px;
    }
  }

  &__tag-unit {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    white-space: nowrap;

    li {
      > a {
        padding: 6px 18px;
        display: inline-block;
        background: #F7F7F7;
        border: 1px #EEE solid;
        border-radius: 9999px;
        color: var.$text-color;
        text-decoration: none;
        @include mix.max-media(var.$screen-md){
          margin: 0 4px;
          padding: 3px 14px 4px;
          font-size: 12px;
        }
      }
    }

  }
}