@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page cross talk
// ============================================================

.page-crossTalk-kv {
  position: relative;

  &__thumb {
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 15, 56, 0) 70%, rgba(0, 15, 56, .3) 95%, rgba(0, 15, 56, .6) 100%);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    margin: 0 40px 2.35% 2.35%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0 25px;
    @include mix.max-media(var.$screen-mlg) {
      margin-bottom: 20px;
    }
    @include mix.max-media(var.$screen-md) {
      position: relative;
      margin: 0;
    }
  }

  &__elm {
    width: 159px;
    @include mix.max-media(var.$screen-md) {
      width: 108px;
      position: absolute;
      top: -10px;
      left: var.$container-md-padding;
      transform: translate(0, -100%);
    }
  }

  &__text-unit {
    @include mix.max-media(var.$screen-md) {
      background: var.$brand-main;
      padding: 24px var.$container-md-padding 27px;
    }

    h2 {
      font-family: var.$font-family-tukushi;
      color: #fff;
      font-weight: 600;
      font-size: 34px;
      line-height: 1.47058824;
      @include mix.max-media(var.$screen-md) {
        margin-bottom: 20px;
        font-size: 18px;
      }
    }

    p {
      color: #fff;
      font-size: 14px;
    }
  }
}

//  @ member block
// ----------------------------------------

.page-cross-talk-member-wrap {
  margin-top: 44px;
  margin-bottom: 60px;
  @include mix.max-media(var.$screen-md){
    margin-top: 64px;
  }
}

.page-cross-talk-member-title {
  margin: 0 0 1px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 60px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  text-align: center;
  @include mix.max-media(var.$screen-md){
    font-size: 36px;
  }
}

.page-cross-talk-member-lead {
  margin-bottom: 24px;
  font-family: var.$font-family-tukushi;
  color: var.$brand-main-darker;
  font-size: 30px;
  text-align: center;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 21px;
    font-size: 18px;
  }
}

.page-cross-talk-member-thumb-wrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.page-cross-talk-member-thumb-group {
  margin: 0 auto;
  text-align: center;
  position: relative;
  display: flex;
  gap: 30px;
  @include mix.max-media(var.$screen-md){
    gap: 18px;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% - 60px);
    background: var.$brand-main-lighter;
    position: absolute;
    top: -21px;
    left: 20px;
    z-index: -1;
    @include mix.max-media(var.$screen-md){
      top: -11px;
      left: 11px;
    }
  }
}

.page-cross-talk-member-thumb {
  width: 300px;
  @include mix.max-media(var.$screen-md){
    width: calc(50% - 18px /2);
  }

  &__img{
    margin-bottom: 20px;
  }

  &__lead {
    font-size: 14px;
    color: var.$brand-main;
    font-family: var.$font-family-tukushi;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 8px;
    @include mix.max-media(var.$screen-md){
      font-size: 11px;
    }
  }

  &__name {
    font-size: 20px;
    color: var.$brand-main;
    font-family: var.$font-family-tukushi;
    font-weight: 600;
    line-height: 1;
    @include mix.max-media(var.$screen-md){}
    font-size: 15px;
  }
}


//  @ page-cross-talk-wrap
// ----------------------------------------

.page-cross-talk-wrap {
  display: flex;
  flex-flow: row wrap;

  @include mix.min-media(var.$screen-lg-min){
    &.is-reverse {
      flex-flow: row-reverse;
    }

    &.is-center {
      justify-content: center;
    }
  }

  @include mix.max-media(var.$screen-md){
    flex-flow: column;
  }
}

.page-cross-talk-thumb {
  width: 50%;
  position: relative;
  @include mix.max-media(var.$screen-md){
    width: 100%;
  }

  &__img {
    width: calc(50vw - 50px);
    max-height: 448px;
    overflow: hidden;
    position: absolute;
    right: 50px;
    @include mix.max-media(var.$screen-md){
      margin: 0 calc(50% - 50vw) 20px;
      width: 100vw;
      position: static;
    }

    .is-reverse & {
      left: 50px;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.page-cross-talk-content {
  width: calc(50%);
  @include mix.max-media(var.$screen-md){
    width: 100%;
  }

  .is-center & {
    width: 100%;
    max-width: 685px;
  }
}

.page-cross-talk-faq {
  margin-bottom: 80px;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 40px;
  }
}

.page-cross-talk-question {
  margin-bottom: 35px;
  padding-left: 85px;
  color: var.$brand-main-darker;
  font-size: 15px;
  font-weight: 700;
  position: relative;
  line-height: 1.6;
  @include mix.max-media(var.$screen-md){
    padding-left: 35px;
    font-size: 14px;
  }

  &:before {
    content: "";
    display: block;
    width: 60px;
    height: 1px;
    background: var.$text-color;
    position: absolute;
    top: 12px;
    left: 0;
    @include mix.max-media(var.$screen-md){
      width: 20px;
    }
  }
}

.page-cross-talk-answer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  @include mix.max-media(var.$screen-md){
    flex-flow: column;
  }

  + dd {
    margin-top: 31px;
  }

  span {
    display: block;
  }

  &__person {
    display: flex;
    width:60px;
    margin-right: 25px;
    color: var.$brand-main-darker;
    font-size: 15px;
    font-weight: 700;
    line-height: 2;
    text-align: right;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 14px;
      font-size: 14px;
      width: 100%;
      text-align: left;
    }

  }

  &__comment {
    display: block;
    width: calc(100% - 85px);
    color: var.$brand-main-darker;
    font-size: 15px;
    line-height: 2;
    @include mix.max-media(var.$screen-md){
      font-size: 14px;
      width: 100%;
    }
  }

}

//  @ page-cross-talk-separation
// ----------------------------------------

.page-cross-talk-separation {
  width: 100vw;
  margin: 0 calc(50% - 50vw) 60px;
}

//  @ page-business-foot-nav
// ----------------------------------------

.page-cross-talk-foot-nav-container {
  overflow: hidden;

  &__inner {
    margin: 0 auto;
    padding: 38px var.$container-lg-padding 64px;
    width: 100%;
    max-width: var.$container-lg;
    position: relative;
    @include mix.max-media(var.$screen-md){
      padding: 0 var.$container-md-padding 35px;
    }

    &:before {
      content: "";
      display: block;
      width: 100vw;
      margin: 0 calc(50vw - 50%) 0 0;
      height: 100%;
      background: #EAF3FA;
      position: absolute;
      top: 20px;
      left: 100px;
      z-index: -1;
      @include mix.max-media(var.$screen-md){
        top: 78px;
        left: 71px;
      }
    }
  }
}

.page-cross-talk-foot-nav-container-title {
  margin-bottom: 10px;
  font-family: var.$font-family-roboto;
  font-size: 46px;
  color: var.$brand-main;
  letter-spacing: var.$font-letter-spacing;
  font-weight: 700;
  @include mix.max-media(var.$screen-md){
    line-height: 1.19565217;
    margin-bottom: 1px;
  }

}

.page-cross-talk-foot-nav-container-lead {
  margin-bottom: 10px;
  font-size: 20px;
  color: var.$brand-main-darker;
  font-weight: 700;
}

.page-cross-talk-foot-nav {
  display: flex;
  flex-flow: row wrap;
  @include mix.max-media(var.$screen-md) {
    display: block;
  }

  &__item {
    width: calc(20%);
    position: relative;
    @include mix.max-media(var.$screen-md) {
      width: 100%;
    }


    a {
      color: #fff;
      display: block;
      @include mix.text-hover();

      &:before {
        background: linear-gradient(to bottom, rgba(0, 15, 56, 0) 60%, rgba(0, 15, 56, 0.3) 85%, rgba(0, 15, 56, 0.6) 100%);
        content: "";
        display: block;
        height: 100%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1;
      }
    }
  }

  &__content {
    padding: 28px 32px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    @include mix.max-media(var.$screen-mlg) {
      padding: 20px 16px;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    @include mix.max-media(var.$screen-mlg) {
      margin-bottom: 2px;
      font-size: 18px;
    }
  }

  &__lead {
    color: #fff;
    font-family: var.$font-family-roboto;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .2em;
    white-space: nowrap;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 11px;
    }
    @include mix.max-media(var.$screen-md){
      font-size: 12px;
    }
  }
}