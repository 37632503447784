@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.is-text-center {
  text-align: center;
}

.is-letter-spacing-100_lg {
  @include mix.min-media(var.$screen-lg-min){
    letter-spacing: .1em;
  }
}

.is-text-strong {
  padding: 0 4px;
  display: inline;
  background:linear-gradient(transparent 45%, #EDDFA6 45%);
}



