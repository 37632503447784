@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ wp-content
// ============================================================

.wp-content {
  margin-bottom: 100px;
  line-height: 2;
  font-size: 15px;
  color: var.$brand-main-darker;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 68px;
  }


  > *:first-of-type {
    margin-top: 0;
  }

  p {
    font-size: 15px;
    margin: 2em 0;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  h1,h2,h3,h4,h5 {
    margin: 2em 0;
  }

  h1 {
    color: var.$brand-main-darker;
    font-size: 2em;
  }

  h2 {
    color: var.$brand-main-darker;
    font-size: 1.5em;
  }

  h3 {
    color: var.$brand-main-darker;
    font-size: 1.25em;
  }

  h4 {
    color: var.$brand-main-darker;
    font-size: 1em;
  }

  h5 {
    color: var.$brand-main-darker;
    font-size: 0.8em;
  }

  h6 {
    color: var.$brand-main-darker;
    font-size: 0.6em;
  }

  ul, ol {
    color: var.$brand-main-darker;
    padding-left: 30px;
  }

  blockquote {
    color: var.$brand-main-darker;
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
  }

  .alignright {
    float: right;
    margin: 0 0 10px 20px;
  }

  .alignleft {
    float: left;
    margin: 0 20px 10px 0;
  }

  ul,
  ol {
    font-size: 15px;
    margin: 2em 0;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    margin: 1em 1.5em 1em;
  }
}