@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ section
// ============================================================

.page-newgaduate-section {
  margin-bottom: 107px;
  @include mix.max-media(var.$screen-md){
    margin: 42px 0 0;
  }
}

.page-recruitment-section {
  max-width: 1031px;
  margin: 0 auto;
  padding: 81px 0 0;
  @include mix.max-media(var.$screen-md){
    padding-top: 65px;
  }
}

.page-newgaduate-foot-section {
  padding-top: 60px;
}

//  @ box
// ----------------------------------------

.page-newgaduate-box {
  margin: 0 auto 85px;
  padding: 50px 30px;
  width: 100%;
  max-width: 1031px;
  background: #FAFAFA;
  border-radius: 10px;
  border: 1px #C6C6C6 solid;
  @include mix.max-media(var.$screen-md){
    padding: 25px 18px 30px;
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 15px;
    font-family: var.$font-family-tukushi;
    color: var.$brand-sub-main;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 21px;
      font-size: 24px;
      line-height: 1.4;
    }
  }

  p {
    margin-bottom: 30px;
    font-size: 15px;
    color: var.$brand-sub-main;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 24px;
      font-size: 14px;
      letter-spacing: 0;
    }
  }

  .notice {
    width: 100%;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
  }
}

//  @ about
// ----------------------------------------

.page-newgaduate-about {
  display: flex;
  flex-flow: row-reverse wrap;

  @include mix.max-media(var.$screen-md) {
    flex-flow: column;
  }

  &__thumb {
    width: 50%;
    position: relative;
    @include mix.max-media(var.$screen-md) {
      width: 100%;
    }
  }

  &__img {
    position: absolute;
    left: 50px;
    width: calc(50vw - 50px);;
    height: 441px;

    @include mix.max-media(var.$screen-md) {
      margin: 0 calc(50% - 50vw) 17px;
      width: 100vw;
      position: static;
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    width: calc(50%);
    @include mix.max-media(var.$screen-md) {
      width: 100%;
    }
  }

  &__title {
    margin: 48px 0 45px;
    font-family: var.$font-family-tukushi;
    color: var.$brand-main-darker;
    font-size: 32px;
    font-weight: 600;
    @include mix.max-media(var.$screen-md){
      margin: 0 0 15px;
      font-size: 20px;
    }
  }

  &__text-unit {

    p {
      &:not(:last-child) {
        margin-bottom: 28px;
      }
    }
  }

}

//  @ recruitment
// ----------------------------------------

.page-recruitment-heading {
  margin-bottom: 64px;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 30px;
  }

  &__title {
    font-size: 100px;
    -webkit-text-stroke: 1px #005cb7;
    color: rgba(0, 0, 0, 0);
    font-family: var.$font-family-roboto;
    font-weight: 700;
    letter-spacing: .2em;
    margin: 0 0 1px;
    text-align: center;
    @include mix.max-media(var.$screen-md){
      font-size: 40px;
    }
  }

  &__lead {
    font-family: var.$font-family-tukushi;
    color: var.$brand-main-darker;
    font-size: 26px;
    text-align: center;
    @include mix.max-media(var.$screen-md){
      font-size: 18px;
    }
  }
}

//  @ message
// ----------------------------------------

.page-newgaduate-foot-message {
  margin-bottom: 70px;
  display: flex;
  flex-flow: row wrap;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 0;
    flex-flow: column;
  }
}

.page-newgaduate-foot-message-thumb {
  width: 50%;
  @include mix.max-media(var.$screen-md){
    width: 100vw;
    margin: 0 calc(50% - 50vw);
  }

  &__img {
    margin: 0 50px 0 auto;
    max-width: 463px;
    @include mix.max-media(var.$screen-md){
      margin: 0 0 17px;
      max-width: 100%;
    }
  }
}

.page-newgaduate-foot-message-content {
  width: 50%;
  @include mix.max-media(var.$screen-md){
    width: 100%;
  }


  &__title {
    margin-bottom: 30px;
    font-family: var.$font-family-tukushi;
    color: var.$brand-main-darker;
    font-size: 32px;
    font-weight: 600;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 14px;
      font-size: 20px;
    }
  }

  &__text-unit {
    font-size: 14px;
    color: var.$brand-main-darker;
  }
}