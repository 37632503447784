@use "./variables" as var;
@use "./functions" as mix;

// @ btn-reset
// ------------------------------------------------------------

@mixin btn-reset {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

//  @ select-reset
// ----------------------------------------

@mixin select-reset {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
}

// @ text-overflow
// ------------------------------------------------------------

@mixin text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin transition-delay($val:var.$hover-transition) {
  transition: all $val ease;
}

@mixin text-hover($val: .6) {
  @include transition-delay();
  &:hover {
    opacity: $val;
  }
}

// @ media
// ------------------------------------------------------------

@mixin max-media($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-media($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin media($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}



