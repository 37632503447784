@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ mv slider
// ============================================================

.mv-slider {
  width: 100%;
  height: 100%;

  &__item {
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(0, 15, 56, 0) 80%, rgba(0, 15, 56, .3) 100%);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}


// ============================================================
//  @ interview slider
// ============================================================

.interview-slider-wrap {
  position: relative;
  width: calc(50vw + 147px);
  @include mix.max-media(var.$screen-md){
    width: 100%;
  }
}


//  @ slider btn
// ----------------------------------------

.interview-slider-arrow-unit {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  position: absolute;
  bottom: -78px;
  left: calc(50% - 120px);

  .swiper-button-next,
  .swiper-button-prev {
    position: static;
    width: 115px;
    height: 9px;
    padding: 10px 0 ;
    background-size: contain;
    background-repeat: no-repeat;
    &:after {
      display: none;
    }
  }

  .swiper-button-next {
    background-image: url('../images/top/interview-slider_right-arrow.svg');
  }

  /* 前ページボタンのスタイル */
  .swiper-button-prev {
    background-image: url('../images/top/interview-slider_left-arrow.svg');
  }
}

//  @ slider
// ----------------------------------------

.interview-slider {
  width: 100%;
  height: 100%;
  overflow: visible;

  //&.swiper{
  //  margin-right: calc(50% - 50vw);
  //}

  &__item {
    margin-right: 7px;
    max-width: 400px;
    min-width: 300px;
    @include mix.max-media(var.$screen-md){
      margin-right: 0;
      max-width: 100%;
      min-width: 100%;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  &__thumb {
    margin: 0 0 12px;
    position: relative;
  }

  &__thumb-label {
    padding: 10px 18px;
    display: inline-block;
    color: #fff;
    font-size: 11px;
    position: absolute;
    bottom: 0;
    left: 0;

    &--01{background: #556393;}
    &--02{background: #AF6262;}
    &--03{background: #6297AF;}
    &--04{background: #6E62AF;}
    &--05{background: #5C9B65;}
  }

  &__genre {
    margin: 0 0 6px;
    color: #fff;
    font-size: 12px;
  }

  &__name-unit {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__name {
    color: #fff;
    margin: 0 16px 0 0;
    padding: 4px 21px 4px 0;
    border-right: 1px #fff solid;
    font-size: 19px;
    font-weight: 700;
    line-height: 1;
  }

  &__en-name {
    color: #fff;
    font-family: var.$font-family-roboto;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .2em;
  }
}

