@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ singe
// ============================================================

.single-head{
  margin-bottom: 50px;
  padding-bottom: 21px;
  border-bottom: 1px #CDD4D9 solid;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 40px;
  }

  &__head {
    margin: 0 0 14px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__cat {
    margin: 0 21px 0 0;
    padding: 9px 16px 9px 18px;
    border: 1px var.$brand-main solid;
    color: var.$brand-main;
    line-height: 1.42;
    text-transform: uppercase;
    @include mix.max-media(var.$screen-md){
      margin-left: 4px;
    }
  }

  &__date {
    font-family: var.$font-family-roboto;
    color: var.$brand-main;
    font-weight: 700;
    letter-spacing: .2em;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.7;
    @include mix.max-media(var.$screen-md) {

    }
  }
}

.single-foot {
  width: 100%;
  position: relative;
}

.single-btn-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  @include mix.max-media(var.$screen-md){
    padding-top: 38px;
  }
}