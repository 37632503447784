@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ footer
// ============================================================

$footer-padding: 6.470588%; //w1700px = padding 110px

.footer {
  padding: 80px 0 95px;
  background: var.$brand-main;
  color: #fff;
  letter-spacing: .05em;
  position: relative;
  @include mix.max-media(var.$screen-md){
    padding: 40px 0 30px;
  }

  &__inner {
    margin: 0 auto;
    padding: 0 $footer-padding;
    width: 100%;
    max-width: 1480px;
    @include mix.max-media(var.$screen-md){
      padding: 0;
    }
  }
}

//  @ f-primary
// ----------------------------------------

.f-primary {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  @include mix.max-media(var.$screen-md){
    flex-flow: column;
  }
}

//  @ f-logo-area
// ----------------------------------------

.f-logo-area {
  margin: 0 9.1176% 0 0;
  @include mix.max-media(var.$screen-md){
    margin: 0;
  }
}

//  @ f-nav-area
// ----------------------------------------

.f-nav-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  @include mix.max-media(var.$screen-md){
    flex-flow: column;
  }
}

.f-nav-first-area{
  margin: 0 11.1849% 0 0;
  @include mix.max-media(var.$screen-md){
    margin: 0;
  }
}

.f-nav-second-area{
  @include mix.max-media(var.$screen-md){
    padding: 30px 26px 0;
  }
}


//  @ f-logo
// ----------------------------------------

.f-logo {
  margin: 0 0 110px;
  width: 100%;
  max-width: 223px;
  @include mix.max-media(var.$screen-md){
    margin: 0 auto 44px;
  }

  a {
    display: block;
    @include mix.text-hover();
  }
}

//  @ f-secondary
// ----------------------------------------

.f-secondary {
  padding: 40px 0 0;
  @include mix.max-media(var.$screen-md){
    padding: 60px 0 0;
    text-align: center;
  }
}

.copyright {
  color: #CCE1FF;
  font-size: 11px;
}
