@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.btn-group-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 40px 20px;
}

// ============================================================
//  @ top-btn01
// ============================================================

.top-btn01 {
  display: block;
  width: 169px;
  text-decoration: none;

  &__inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
  }

  &__elm-round {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: var.$brand-main;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .is-btn-color-white & {
      background: #fff;
    }
  }

  &__elm-front-arrow {
    position: absolute;
    bottom: 16px;
    left: 26px;
    @include mix.transition-delay();

    img {
      width: 143px;
    }
  }

  &__text {
    margin: 0 0 6px 14px;
    font-family: var.$font-family-roboto;
    color: var.$brand-main-darker;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .2em;
    white-space: nowrap;

    .is-btn-color-white & {
      color: #fff;
    }
  }

  &__elm-back-arrow {
    position: absolute;
    bottom: 16px;
    left: 26px;
    @include mix.transition-delay();

    img {
      width: 143px;
    }
  }

  // :hover

  &:hover {
    .top-btn01__elm-front-arrow,
    .top-btn01__elm-back-arrow {
      left: 54px;
    }
  }
}

// ============================================================
//  @ top-btn02
// ============================================================

.top-btn02 {
  padding: 0 57px;
  height: 78px;
  background: var.$brand-main-lighter;
  border-radius: 100px;
  color: var.$brand-main-darker;
  border: 1px var.$brand-main-darker solid;
  font-size: 26px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @include mix.transition-delay();
  @include mix.max-media(var.$screen-md) {
    padding: 0 26px;
    height: 48px;
    font-size: 18px;
    line-height: 38px;
  }

  &:hover {
    background: var.$brand-main-darker;
    color: #fff;
  }

  &.is-active {
    background: #fff;
    border-radius: 100px;
    color: var.$brand-main-darker;
    border: 1px #fff solid;
    pointer-events: none;
  }
}

// ============================================================
//  @ page-btn01
// ============================================================

.page-btn01 {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  min-width: 214px;

  &.is-btn-reverse {
    transform: scaleX(-1);
  }

  &__inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
  }

  &__elm-round {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: var.$brand-main;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .is-btn-color-white & {
      background: #fff;
    }
  }

  &__elm-front-arrow {
    position: absolute;
    bottom: 16px;
    left: 26px;
    @include mix.transition-delay();

    img {
      width: 143px;
    }
  }

  &__text {
    width: calc(100% - 56px - 14px);
    margin: 0 0 6px 14px;
    padding-right: 30px;
    font-family: var.$font-family-roboto;
    color: var.$brand-main-darker;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .2em;
    white-space: nowrap;

    .is-btn-reverse &{
      transform: scaleX(-1);
      margin: 0 14px 6px 0;
      padding: 0 0 0 10px;
    }

    .is-btn-color-white & {
      color: #fff;
    }
  }

  &__elm-back-arrow {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 16px;
    @include mix.transition-delay();

    &--arrow {
      width: auto;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &--horizon {
      width: 50%;
      height: 2px;
      object-fit: cover;
      position: absolute;
      bottom: 0;
      left: 4px;
    }
  }

  // :hover

  &:hover {
    .page-btn01__elm-front-arrow{
      left: 54px;
    }
    .page-btn01__elm-back-arrow {
      right: -10px;
    }
  }
}

//  @ btn02
// ============================================================

.btn02 {
  padding: 20px 65px;
  background: var.$brand-main;
  border-radius: 9999px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @include mix.text-hover();
  @include mix.max-media(var.$screen-md){
    width: 100%;
    max-width: 414px;
    padding: 14px 20px;
    text-align: center;
    line-height: 1.4;
  }
}