@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ sitemap
// ============================================================

.sitemap-list {

  li {
    margin-bottom: 20px;

    > a {
      color: var.$brand-main-darker;
      font-size: 15px;
      font-weight: 700;
      text-decoration: none;
      @include mix.text-hover();
    }
  }
}

.sitemap-sub-list {
  margin-bottom: 20px;
  padding-left: 15px;
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  li {

    > a {
      color: var.$brand-main-darker;
      font-size: 15px;
      font-weight: 400;
      text-decoration: none;
      @include mix.text-hover();
    }
  }
}

