.an_fadeUp {
  animation-duration: 0.6s;
  opacity: 0;
}

.an_expandLeftToRight {
  animation-duration: 0.3s;
  transform-origin:left;
  transform: scale3d(0, 1, 1);
}


.js_isActive {
  &.an_fadeIn {
    animation-name: fadeIn;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
  }

  &.an_fadeOut {
    animation-name: fadeOut;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
  }

  &.an_fadeUp {
    animation-name: fadeUp;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  &.an_expandLeftToRight {
    animation-name: expandLeftToRight;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
  }

  &.an_fadeInLeft {
    animation-name: fadeInLeft;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  &.an_fadeInLeftSmall {
    animation-name: fadeInLeftSmall;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  &.an_fadeInRight {
    animation-name: fadeInRight;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  &.an_fadeInRightSmall {
    animation-name: fadeInRightSmall;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  &.an_maskInLeft {
    animation-name: maskInLeft;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  &.an_maskInRight {
    animation-name: maskInRight;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  .an_maskInLeft_sequential {
    animation-name: maskInLeft;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  .an_maskInRight_sequential {
    animation-name: maskInRight;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  &.an_gradBlue {
    animation-name: gradBlue;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }

  &.an_gradRed {
    animation-name: gradRed;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }
}

@for $i from 1 through 160 {
  .an_delay_#{$i*10} {
    animation-delay: $i * 0.1s;
  }
  .tr_delay_#{$i*10} {
    transition-delay: $i * 0.1s;
  }
  .an_dur_#{$i*10} {
    animation-duration: $i * 0.1s;
  }
  .tr_dur_#{$i*10} {
    transition-duration: $i * 0.1s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-40%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeftSmall {
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(40%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRightSmall {
  from {
    opacity: 0;
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeftInterview {
  from {
    opacity: 0;
    transform: translate3d(-40%, calc(-100% + 18.14vh), 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, calc(-100% + 18.14vh), 0);
  }
}

@keyframes fadeInRightInterview {
  from {
    opacity: 0;
    transform: translate3d(40%, calc(-100% + 18.14vh), 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, calc(-100% + 18.14vh), 0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes colorTrans {
  0% {
    color: transparent;
  }
  100% {
    color: #000;
  }
}

@keyframes maskInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes maskInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes expandLeftToRight {
  0% {
    transform: scale3d(0, 1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 13em;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: transparent;
  }
  to {
    border-right-color: #fff;
  }
}

@keyframes tranUp {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes imgTranLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-200px, 0, 0);
  }
}

@keyframes imgTranLeftPC {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-400px, 0, 0);
  }
}

@keyframes imgTranRight {
  0% {
    transform: translate3d(200px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes imgTranLeftEpi {
  0% {
    transform: translate3d(-250px, 0, 0);
  }
  100% {
    transform: translate3d(-600px, 0, 0);
  }
}

@keyframes zoomInLeft {
  0% {
    transform: translate(-50%, 20%) scale3d(0, 0, 0);
  }
  100% {
    transform: translate(-50%, 0) scale3d(1, 1, 1);
  }
}

@keyframes gradBlue {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: blue;
  }
}

@keyframes gradRed {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #bd261a;
  }
}
