// ============================================================
//  @Colors
// ============================================================

$brand-primary: #0082aa;
$brand-success: #137E00;
$brand-info: #4A90B9;
$brand-warning: #FF7F00;
$brand-error: #C60000;
$brand-disable: #888888;

// brand color
// -------------------

$brand-main: #005CB7;
$brand-sub-main: #00478E;

$brand-main-lighter: #EAF3FA;
$brand-main-lighten: $brand-main;

$brand-main-darker: #103B65;
$brand-main-darken: $brand-main;

$brand-gray-lighten: #999999;
$brand-gray-darker: #616265;
$brand-gray-darken: #04122c;

// text color
// -------------------
$text-color: #333;
$text-color-sub: #888888;
$text-color-placeholder: #BCBCBC;
$text-color-disable: $brand-disable;
$text-color-white: #fff;
$heading-color: #231815;
$border-color-base: #DEDEDE;

// link color
// -------------------
$link-color: #003C88;
$link-visited-color: $link-color;
$link-active-color: $link-color;
$link-hover-color: $link-color;

// ============================================================
// @ タイポグラフィ設定
// ============================================================

// ベースフォント設定
// -------------------
$font-family-base: 'Noto Sans JP', "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
$font-family-roboto: 'Roboto Condensed', "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
$font-family-tukushi: 'FOT-筑紫Aオールド明朝 Pr6 D', 'TsukuAOldMinPr6-D', serif;
$font-family-gothic: 'A P-OTF A1ゴシック StdN', 'TsukuAOldMinPr6-D', sans-serif;
$font-size-base: 14px;
$font-size-heading: $font-size-base;
$headings-color: #333;
$line-height-base: 2;
$line-height-heading: 1.4;
$font-letter-spacing: 0.2em;

// ============================================================ //
// @ transition & hover opacity 設定
// ============================================================ //

$hover-transition: .3s;
$hover-opacity: .7;


// ============================================================
// @ 全体構造定義
// ============================================================

$header_H               :86px;
$header-primary-menu_H  :60px;

// ============================================================ //
// @ Z-index定義
// ============================================================ //

$loading-layer    : 99999;
$ie_alert-layer   : 99000;
$zindex-h-toggle  : 10000;
$zindex-drawer    : 9999;
$zindex-header    : 9000;

// ============================================================ //
// @ メディアクエリ設定
// ============================================================ //

// Extra small screen / phoneSE
$screen-xs               :  320px;
$screen-xs-min           :  $screen-xs;

// Small Mid Small screen / Iphone
$screen-sm               :  414px;
$screen-msm               :  375px;
$screen-msm-min           :  $screen-sm;

// Small screen / Iphone Plus

$screen-sm-min           :  $screen-sm;

// iphone5 land
$screen-5ls              :  568px;

// iphone6 land
$screen-6ls              :  667px;

// Medium screen
$screen-md               :  768px;
$screen-md               :  768px;
$screen-md-min           :  $screen-sm + 1;
$screen-desktop          :  $screen-md-min;

// Small large screen
$screen-slg               :  940px;
$screen-slg-min           :  $screen-md + 1;

// Mid large screen
$screen-mlg               :  1024px;
$screen-mlg-min           :  $screen-slg + 1;

// Large screen
$screen-lg               :   1360px;
$screen-lg-min           :   $screen-md + 1;

// Xlarge screen
$screen-xlg               :   1460px;
$screen-xlg-min           :   $screen-lg + 1;


$blakepoints: (
        'xlg' : $screen-xlg,
        'lg'  : $screen-lg,
        'mlg' : $screen-mlg,
        'slg' : $screen-slg,
        'md'  : $screen-md,
        '6ls' : $screen-6ls,
        '5ls' : $screen-5ls,
        'sm'  : $screen-sm,
        'msm' : $screen-msm,
        'xs'  : $screen-xs,
);

// ============================================================
//  @container
// ============================================================

$container-lg-padding: 50px;
$container-lg: $screen-lg + $container-lg-padding * 2;

$container-md-padding: 5.33%;





