@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ label-required
// ============================================================

.label-required {
  margin-left: 10px;
  padding: 4px 10px 5px;
  display: inline-block;
  border-radius: 9999px;
  background: var.$brand-error;
  color: #fff;
  font-size: 11px;
}

// ============================================================
//  @ contact-table
// ============================================================

.form-table {
  margin-bottom: 62px;
  padding: 31px 0 14px;
  width: 100%;
  border-bottom: 1px #DBDBDB solid;
  border-spacing: 10px 0;

  th, td {
    display: block;
  }

  th {
    padding: 9px 0 9px 5px;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  td {
    margin-bottom: 31px;

    p {
      display: inline-block;
    }
  }
}

// ============================================================
//  @ table01
// ============================================================

.table01 {
  width: 100%;
  border-top: 1px #DBDBDB solid;

  tr {
    border-bottom: 1px #DBDBDB solid;

  }

  th,td{
    padding: 40px 0;
    font-size: 15px;
    color: var.$brand-main-darker;
    text-align: left;
    @include mix.max-media(var.$screen-md){
      font-size: 14px;
      display: block;
    }
  }

  th {
    width: calc(100% - 78.26%);
    font-weight: 700;
    @include mix.max-media(var.$screen-md){
      padding: 20px 0 10px;
      width: 100%;
    }
  }

  td {
    width: 78.26%;
    line-height: 2;
    @include mix.max-media(var.$screen-md){
      padding: 10px 0 18px;
      width: 100%;
    }
  }
}
