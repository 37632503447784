@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ heading
// ============================================================

.heading01 {
  margin: 0 0 25px;
  padding: 14px 17px;
  background: var.$brand-main-darker;
  font-family: var.$font-family-tukushi;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  line-height: 1.5;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 20px;
    padding: 12px 15px;
    font-size: 18px;
  }
}

.heading02 {
  margin-bottom: 49px;
  font-family: var.$font-family-tukushi;
  color: var.$brand-main-darker;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.5;
  @include mix.max-media(var.$screen-md){
    font-size: 20px;
  }
}

.heading03 {
  margin: 0 0 40px;
  padding: 0 0 15px;
  border-bottom: 1px #DBDBDB solid;
  color: var.$brand-main-darker;
  font-size: 20px;
  font-weight: 700;
}

//  @ page-heading01
// ----------------------------------------

.page-heading01 {
  margin-bottom: 30px;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 15px;
  }

  &__title {
    font-family: var.$font-family-roboto;
    color: var.$brand-main;
    margin-bottom: 6px;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: var.$font-letter-spacing;
    @include mix.max-media(var.$screen-md){
      font-size: 24px;
    }
  }

  &__lead {
    color: var.$brand-main-darker;
    font-size: 20px;
    font-weight: 700;
    @include mix.max-media(var.$screen-md){
      font-size: 15px;
    }
  }
}

.page-heading02 {
  margin:  60px 0 30px;
  padding-left: 26px;
  font-size: 18px;
  color: var.$brand-main-darker;
  line-height: 1.55555556;
  position: relative;

  &:before {
    background: #333;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 12px;
    width: 15px;
    @include mix.max-media(var.$screen-md){
      width: 10px;
      top: 10px;
      margin:  30px 0 15px;
    }
  }

  @include mix.max-media(var.$screen-md){
    padding-left: 18px;
    font-size: 14px;
  }
}

.page-heading03 {
  margin:  60px 0 30px;
  padding: 4px 8px 5px;
  display: inline-block;
  background: var.$brand-main-darker;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  @include mix.max-media(var.$screen-md){
    font-size: 14px;
    margin:  30px 0 15px;
  }
}