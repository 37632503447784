@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page message
// ============================================================

.page-service-section {
  margin-top: 111px;
  @include mix.max-media(var.$screen-md){
    margin: 125px 0 0;
  }
}

//  @ page-service-block
// ----------------------------------------

.page-service-block {
  margin-bottom: 50px;
  position: relative;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 64px;
  }

  &:before {
    content: "";
    display: block;
    width: 100vw;
    height: 446px;
    margin: 0 calc(50% - 50vw - 23.52vw) 0 0;
    background: var.$brand-main-lighter;
    position: absolute;
    top: 54px;
    right: 0;
    z-index: -1;
    @include mix.max-media(var.$screen-md) {
      margin: 0 calc(50% - 50vw - 26.66vw) 0 0;
      height: 439px;
      top: -38 px;
    }
  }
}

.page-service-block-heading {
  margin: 0 0 60px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  hyphens: manual;

  @include mix.max-media(var.$screen-md) {
    margin-bottom: 34px;
    font-size: 50px;
  }

  span {
    hyphens: auto;
  }
}

.page-service-block-wrap {
  width: 100%;
  position: relative;
}

.page-service-block-thumb {
  margin: 0 0 30px auto;
  width: 91.54%;
  height: 442px;

  @include mix.max-media(var.$screen-md) {
    width: 100vw;
    height: auto;
    margin: 0 calc(50% - 50vw) 0;
    padding-left: 12vw;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-service-block-content {
  position: absolute;
  bottom: 219px;
  left: 0;
  @include mix.max-media(var.$screen-md) {
    margin-top: -30px;
    position: static;
    display: block;
  }
}

.page-service-block-title {
  width: 100%;
  margin: 0 0 0;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 6px;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md) {
    gap: 12px;
    margin-bottom: 40px;
  }

  p {
    padding: 0 19px 0 21px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }

    @include mix.max-media(var.$screen-md) {
      padding: 13px 12px 12px 11px;
      font-size: 30px;
      height: auto;
    }
  }
}

//  @ list01
// ----------------------------------------

.page-service-list01 {
  margin-bottom: 44px;
  display: flex;
  flex-flow: row wrap;
  gap: 47px 20px;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 46px;
    gap: 37px 0;
  }

  &__item {
    width: calc(33.33% - 20px * 2 / 3);
    @include mix.max-media(var.$screen-md) {
      width: 100%;
    }
  }

  &__thumb {
    margin-bottom: 28px;
  }

  &__content {
    color: var.$brand-main-darker;
  }

  &__title {
    margin-bottom: 22px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .05em;
  }

  ul {
    padding-left: 17px;

    li {
      position: relative;
      line-height: 1.2;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &:before {
        content: "";
        display: block;
        width: 3px;
        height: 3px;
        background: var.$brand-main-darker;
        position: absolute;
        top: 50%;
        left: -11px;
        transform: translate(0, -50%);
      }
    }
  }
}