@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//  README
// ------------------------------------------------------------
//  ユーティリティ用のフォントサイズクラスを使用する場合「$utilityFontsizeClass」を「true」にする
//  $endValueはpxの最大値を表す
// ------------------------------------------------------------

$utilityWidthClass: true !default;
$endValue: 10 !default;

// padding class
// ------------------------------------------------------------

@for $i from 0 through $endValue {
  @if $utilityWidthClass {
    .w_#{$i * 10}p {
      width: #{($i * 10) + '%'};
    }
    @include mix.max-media(var.$screen-xlg) {
      .w_#{$i * 10}p--xlg {
        width: #{($i * 10) + '%'};
      }
    }
    @include mix.max-media(var.$screen-lg) {
      .w_#{$i * 10}p--lg {
        width: #{($i * 10) + '%'};
      }
    }
    @include mix.max-media(var.$screen-mlg) {
      .w_#{$i * 10}p--mlg {
        width: #{($i * 10) + '%'};
      }
    }
    @include mix.max-media(var.$screen-slg) {
      .w_#{$i * 10}p--slg {
        width: #{($i * 10) + '%'};
      }
    }
    @include mix.max-media(var.$screen-md) {
      .w_#{$i * 10}p--md {
        width: #{($i * 10) + '%'};
      }
    }
    @include mix.max-media(var.$screen-6ls) {
      .w_#{$i * 10}p--6ls {
        width: #{($i * 10) + '%'};
      }
    }
    @include mix.max-media(var.$screen-5ls) {
      .w_#{$i * 10}p--5ls {
        width: #{($i * 10) + '%'};
      }
    }
    @include mix.max-media(var.$screen-sm) {
      .w_#{$i * 10}p--sm {
        width: #{($i * 10) + '%'};
      }
    }
    @include mix.max-media(var.$screen-msm) {
      .w_#{$i * 10}p--msm {
        width: #{($i * 10) + '%'};
      }
    }
    @include mix.max-media(var.$screen-xs) {
      .w_#{$i * 10}p--xs {
        width: #{($i * 10) + '%'};
      }
    }
  }
}
