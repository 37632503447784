@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ form
// ============================================================

.form-control {
  width: 100%;
  display: inline-block;
}

.form-text-unit {
  font-size: 14px;
  color: var.$brand-main-darker;
  text-align: center;

  p{
    > a {
      color: var.$brand-main-darker;
      text-decoration: underline;
    }
  }
}

//  @ wpcf7-not-valid-tip
// ----------------------------------------

span.wpcf7-not-valid-tip {
  margin-top: 10px;
  font-weight: 700;
}

//  @ input
// ----------------------------------------

input[type="text"],
input[type="tel"],
input[type="email"] {
  padding: 16px 14px 14px;
  border: 1px #999999 solid;
  border-radius: 5px;
  font-size: 15px;
}


.is-form-age {

  .fld-joblist-age {
    position: relative;

    &:before {
      content: "歳";
      display: block;
      position: absolute;
      top: calc(50% + 2px);
      right: -5px;
      transform: translate(100%, -50%);
    }
  }

}
//  @ select
// ----------------------------------------

//  @ textarea
// ----------------------------------------

textarea {
  padding: 16px 14px 14px;
  border: 1px #999999 solid;
  border-radius: 5px;
  font-size: 15px;
}


.select {
  position: relative;

  // select tag reset
  select {
    @include mix.select-reset;

    padding: 14px 35px 13px 25px;
    width: 100%;
    border-radius: 25px;
    border: 1px var.$brand-gray-lighten solid;
    color: var.$brand-gray-lighten;
    font-size: 15px;
  }

  &:before {
    content: "";
    display: block;
    width: 14px;
    height: 7px;
    background: url("../images/common/form-select-under-arrow.svg") center center no-repeat;
    background-size: cover;
    pointer-events: none;

    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
  }
}

// ============================================================
//  @
// ============================================================

.wpcf7 form.invalid .wpcf7-response-output {
  padding: 32px;
  font-size: 15px;
  text-align: center;
  @include mix.max-media(var.$screen-md){
    font-size: 14px;
  }
}