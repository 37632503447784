@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ mv
// ============================================================

.main-visual {
  height: 100vh;
  position: relative;
  @include mix.max-media(var.$screen-md) {
  }


  &__elm01, &__elm02 {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 3;

    img {
      width: auto;
      height: 100%;
    }
  }

  &__elm01 {
    height: 72.39vh;
    right: 18.52%;
    @include mix.max-media(var.$screen-mlg){
      right: 40px;
    }

    @include mix.max-media(var.$screen-md) {
      width: auto;
      min-width: auto;
      height: 80%;
      top: auto;
      bottom: 31px;
      left: 20px;
      transform: translate(0, 0);
    }
  }

  &__elm02 {
    height: 70.635983vh;
    left: 21.88%;
    @include mix.max-media(var.$screen-mlg){
      left: 40px;
    }
  }

  &__scroll-elm{
    position: absolute;
    bottom: 0;
    right: 55px;
    z-index: 1;
    @include mix.max-media(var.$screen-mlg){
      right: 20px;
    }
  }
}