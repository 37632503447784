@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page message
// ============================================================

.page-message-section {
  margin-top: 111px;
  @include mix.max-media(var.$screen-md){
    margin: 125px 0 0;
  }
}

//  @ page-message-block
// ----------------------------------------

.page-message-block {
  margin-bottom: 100px;
  position: relative;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 112px;
  }

  &:before {
    content: "";
    display: block;
    width: 100vw;
    height: 446px;
    margin: 0 calc(50% - 50vw - 23.52vw) 0 0;
    background: var.$brand-main-lighter;
    position: absolute;
    top: 54px;
    right: 0;
    z-index: -1;
    @include mix.max-media(var.$screen-md) {
      margin: 0 calc(50% - 50vw - 26.66vw) 0 0;
      height: 491px;
      top: -64px;
    }
  }
}

.page-message-block-heading {
  margin: 0 0 60px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  hyphens: manual;

  @include mix.max-media(var.$screen-md) {
    margin-bottom: 34px;
    font-size: 50px;
  }

  span {
    hyphens: auto;
  }
}

.page-message-block-wrap {
  width: 100%;
  position: relative;
}

.page-message-block-thumb {
  margin: 0 0 30px auto;
  width: 91.54%;
  height: 442px;

  @include mix.max-media(var.$screen-md) {
    width: 100vw;
    height: auto;
    margin: 0 calc(50% - 50vw) 0;
    padding-left: 12vw;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-message-block-content {
  position: absolute;
  bottom: -25px;
  left: 0;
  @include mix.max-media(var.$screen-md) {
    margin-top: 12px;
    position: static;
    display: block;
  }
}

.page-message-block-title {
  width: 100%;
  margin: 0 0 0;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 6px;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 40px;
  }

  p {
    padding: 0 19px 0 21px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }

    @include mix.max-media(var.$screen-md) {
      padding: 0 11px 0 7px;
      font-size: 16px;
      height: 40px;
    }
  }
}

//  @ page-message-text-unit
// ----------------------------------------

.page-message-text-unit {
  margin: 0 auto 42px;
  max-width: 1026px;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 44px;
  }

  p {
    font-size: 15px;
    &:not(:last-child){
      margin-bottom: 36px;
      @include mix.max-media(var.$screen-md){
        margin-bottom: 30px;
      }
    }
  }
}

.page-message-seo-name {
  margin: 0 auto;
  max-width: 1026px;
  p {
    font-family: var.$font-family-tukushi;
    font-size: 16px;
    text-align: right;
    line-height: 1.7;
  }
}