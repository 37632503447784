@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page message
// ============================================================

.page-flow-section {
  margin: 78px auto 42px;
  max-width: 1026px;
  @include mix.max-media(var.$screen-md) {
    margin-top: 68px;
    margin-bottom: 0;
  }
}

.page-flow-block {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 77px;

    &:before {
      content: "";
      display: block;
      width: 56px;
      height: 35px;
      background: url("../images/page-flow/icon-arrow.svg") center center no-repeat;
      background-size: cover;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translate(-50%, 100%);
      @include mix.max-media(var.$screen-md){
        bottom: -17px;
      }
    }
  }
}

.page-flow-heading {
  margin-bottom: 11px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 6px;
  }

  &__elm {
    font-family: var.$font-family-roboto;
    color: #EAF3FA;
    font-weight: 700;
  }

  &__elm-text {
    margin-left: 19px;
    color: #EAF3FA;
    font-size: 30px;
    line-height: 1;
    letter-spacing: var.$font-letter-spacing;
    @include mix.max-media(var.$screen-md) {
      font-size: 25px;
    }
  }

  &__elm-num {
    color: #EAF3FA;
    font-size: 100px;
    line-height: 1;
    letter-spacing: var.$font-letter-spacing;
    @include mix.max-media(var.$screen-md) {
      font-size: 83px;
    }
  }

  &__title {
    margin-left: -84px;
    font-family: var.$font-family-tukushi;
    color: var.$brand-main-darker;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5;
    @include mix.max-media(var.$screen-md) {
      margin-left: -68px;
      margin-bottom: -11px;
      font-size: 20px;
    }
  }
}

.page-flow-wrap {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  @include mix.max-media(var.$screen-md){
    flex-flow: column-reverse;
  }
}

.page-flow-text-unit {
  width: calc(100% - 344px - 20px);
  @include mix.max-media(var.$screen-md){
    width: 100%;
  }
}

.page-flow-thumb {
  width: 344px;
  @include mix.max-media(var.$screen-md){
    width: 100%;
  }
}

//
// ------------------------------------------------------------

.flow-block--newgaduate{

  .page-flow-heading{
    margin-bottom: 30px;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 20px;
    }
  }
  .page-flow-heading__elm-text{
    font-size: 20px;
    color: #e9e9e9;
    @include mix.max-media(var.$screen-md){
      font-size: 14px;
    }
  }
  .page-flow-heading__elm-num{
    color: #e9e9e9;
    @include mix.min-media(var.$screen-md){
      font-size: 70px;
    }
    @include mix.max-media(var.$screen-md){
      font-size: 60px;
    }
  }
  .page-flow-heading__title{
    font-size: 20px;
    @include mix.max-media(var.$screen-md){
      font-size: 16px;
    }
  }
}