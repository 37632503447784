@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ card
// ============================================================

.card01 {
  display: flex;
  flex-flow: row wrap;
  gap: 78px 20px;
  @include mix.max-media(var.$screen-md){
    gap: 34px 0;
  }

  &__item {
    width: calc(33.33% - 20px * 2 / 3);
    display: flex;
    flex-flow: column wrap;
    @include mix.max-media(var.$screen-md){
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 7px;
    padding-left: 26px;
    font-size: 18px;
    color: var.$brand-main-darker;
    line-height: 1.55555556;
    position: relative;

    &:before {
      background: #333;
      content: "";
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      top: 12px;
      width: 15px;
    }
  }

  &__thumb {
    margin-bottom: 18px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text-unit{
    margin-bottom: 20px;
  }

  &__btn {
    margin: auto 0 0;
    padding: 0 20px;
    width: 100%;
    height: 47px;
    background: var.$brand-main;
    border-radius: 9999px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}
