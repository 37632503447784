@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page employee
// ============================================================

.page-employee-section {
  padding: 50px 0 0;
  @include mix.max-media(var.$screen-md){
    padding: 40px var.$container-md-padding 0;
  }
}

//  @ page-employee-wrap
// ----------------------------------------

.page-employee-wrap {
  margin-bottom: 80px;
  display: flex;
  flex-flow: row wrap;

  @include mix.min-media(var.$screen-lg-min){
    &.is-reverse {
      flex-flow: row-reverse;
    }

    &.is-center {
      justify-content: center;
    }
  }

  @include mix.max-media(var.$screen-md){
    margin-bottom: 60px;
    flex-flow: column;
  }
}

.page-employee-thumb {
  width: 50vw;
  @include mix.max-media(var.$screen-md){
    width: 100%;
  }

  &__img {
    padding: 0 50px 0 0;
    width: 100%;
    @include mix.max-media(var.$screen-md){
      padding: 0;
      margin: 0 calc(50% - 50vw) 20px;
      width: 100vw;
    }

    .is-reverse & {
      padding: 0 0 0 50px;
      @include mix.max-media(var.$screen-md){
        padding: 0;
      }
    }
  }
}

.page-employee-content {
  padding: 0 50px 0 0;
  width: calc(50%);
  max-width: 685px;
  @include mix.max-media(var.$screen-md){
    padding: 0;
    width: 100%;
  }

  .is-reverse & {
    padding: 0 0 0 50px;
    @include mix.max-media(var.$screen-md){
      padding: 0;
    }
  }
}

.page-employee-faq {
  &:not(:last-child) {
    margin-bottom: 80px;
    @include mix.max-media(var.$screen-md) {
      margin-bottom: 40px;
    }
  }
}

.page-employee-question {
  margin-bottom: 40px;
  padding-left: 85px;
  color: var.$brand-main-darker;
  font-size: 15px;
  font-weight: 700;
  position: relative;
  line-height: 1.6;
  @include mix.max-media(var.$screen-md){
    padding-left: 35px;
    font-size: 14px;
  }

  &:before {
    content: "";
    display: block;
    width: 60px;
    height: 1px;
    background: var.$text-color;
    position: absolute;
    top: 12px;
    left: 0;
    @include mix.max-media(var.$screen-md){
      width: 20px;
    }
  }
}

.page-employee-answer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  @include mix.max-media(var.$screen-md){
    flex-flow: column;
  }

  span {
    display: block;
  }

  &__person {
    display: flex;
    width: 85px;
    color: var.$brand-main-darker;
    font-size: 15px;
    font-weight: 700;
    line-height: 2;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 14px;
      font-size: 14px;
      width: 100%;
    }

  }

  &__comment {
    display: block;
    width: calc(100% - 85px);
    color: var.$brand-main-darker;
    font-size: 15px;
    line-height: 2;
    @include mix.max-media(var.$screen-md){
      font-size: 14px;
      width: 100%;
    }
  }

}

//  @ page-employee-separation
// ----------------------------------------

.page-employee-separation {
  width: 100vw;
  margin: 0 calc(50% - 50vw) 60px;
}

//  @ page-employee-btn-group
// ----------------------------------------

.page-employee-btn-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
