@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page culture
// ============================================================

.page-culture-list01 {
  margin-bottom: 100px;
  display: flex;
  flex-flow: row wrap;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 60px;
  }

  li {
    &:nth-child(1), {
      width: 100%;
      border-bottom: 1px #DBDBDB solid;
    }

    &:nth-child(2) {
      width: 50%;
      border-right: 1px #DBDBDB solid;
      border-bottom: 1px #DBDBDB solid;
      @include mix.max-media(var.$screen-md){
        border-right: none;
        width: 100%;
      }
    }

    &:nth-child(3) {
      width: 50%;
      border-bottom: 1px #DBDBDB solid;
      @include mix.max-media(var.$screen-md){
        width: 100%;
      }
    }

    &:nth-child(4) {
      width: 45.82%;
      border-right: 1px #DBDBDB solid;
      border-bottom: 1px #DBDBDB solid;
      @include mix.max-media(var.$screen-md){
        border-right: none;
        width: 100%;
      }
    }

    &:nth-child(5) {
      width: 29.41%;
      border-right: 1px #DBDBDB solid;
      border-bottom: 1px #DBDBDB solid;
      @include mix.max-media(var.$screen-md){
        border-right: none;
        width: 100%;
      }
    }

    &:nth-child(6), {
      width: calc(100% - 45.82% - 29.41%);
      border-bottom: 1px #DBDBDB solid;
      @include mix.max-media(var.$screen-md){
        width: 100%;
      }
    }

    &:nth-child(7) {
      width: 50%;
      border-right: 1px #DBDBDB solid;
      @include mix.max-media(var.$screen-md){
        border-right: none;
        width: 100%;
      }
    }

    &:nth-child(8) {
      width: 50%;
      @include mix.max-media(var.$screen-md){
        display: none;
      }
    }
  }
}

