@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as func;

// ============================================================ //
// @ page top
// ============================================================ //

.page-top {
  position: absolute;
  bottom: 95px;
  right: 56px;
  opacity: 1;
  cursor: pointer;
  @include mix.transition-delay();
  @include mix.max-media(var.$screen-md){
    bottom: 106px;
    right: var.$container-md-padding;
  }
  
  a {
    display: block;
    width: 40px;
    @include mix.text-hover();
    @include mix.max-media(var.$screen-md){
      width: 30px;
    }
  }
  
  &.is-visible {
    opacity: 1;
    bottom: 92px;
  }
}