@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page about
// ============================================================

.page-about-wrap {
  padding-top: 20px;
  @include mix.max-media(var.$screen-md) {
    padding-top: 40px;
  }
}

//  @ page-about-block
// ----------------------------------------

.page-about-block {
  position: relative;


  &:not(:last-child) {
    margin-bottom: 100px;
    @include mix.max-media(var.$screen-md) {
      margin-bottom: 68px;
    }
  }

  &:before {
    content: "";
    display: block;
    width: 100vw;
    height: 446px;
    margin: 0 calc(50% - 50vw - 23.52vw) 0 0;
    background: var.$brand-main-lighter;
    position: absolute;
    top: 54px;
    right: 0;
    z-index: -1;
    @include mix.max-media(var.$screen-md) {
      margin: 0 calc(50% - 50vw - 26.66vw) 0 0;
      height: 491px;
      top: 20px;
    }
  }

  &.is-members {
    &:before {
      @include mix.min-media(var.$screen-lg-min) {
        height: 386px;
      }
    }
  }

  &.is-reverse {

    @include mix.min-media(var.$screen-lg-min) {
      &:before {
        margin: 0 0 0 calc(50% - 50vw - 23.52vw);
        right: auto;
        left: 0;
      }
    }
  }
}

.page-about-block-heading {
  margin: 0 0 60px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  hyphens: manual;

  @include mix.max-media(var.$screen-md) {
    margin-bottom: 30px;
    font-size: 50px;
  }

  .is-reverse & {
    text-align: right;
    @include mix.max-media(var.$screen-md) {
      text-align: left;
    }
  }

  span {
    hyphens: auto;
  }
}

.page-about-block-wrap {
  width: 100%;
  position: relative;
}

.page-about-block-thumb {
  margin: 0 0 30px auto;
  width: 91.54%;
  height: 442px;

  @include mix.max-media(var.$screen-md) {
    width: 100vw;
    height: auto;
    margin: 0 calc(50% - 50vw) 0;
    padding-left: 12vw;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      margin: 0 auto 30px 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-about-block-content {
  position: absolute;
  bottom: 0;
  left: 0;
  @include mix.max-media(var.$screen-md) {
    margin-top: -25px;
    margin-bottom: 60px;
    position: static;
    display: block;
  }

  .is-reverse & {
    left: auto;
    right: 0;
  }

  .is-members & {
    width: 100%;
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
    align-items: center;
    left: 0;
    right: auto;
    @include mix.max-media(1360px){
      margin-top: -135px;
      margin-bottom: 0;
      position: static;
      display: block;
    }
    @include mix.max-media(var.$screen-md){
      margin-top: -22px;
      margin-bottom: 60px;
    }
  }
}

.page-about-block-title {
  width: 100%;
  margin: 0 0 121px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 40px;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min) {
      justify-content: right;
    }
  }

  .is-message &, .is-business &, .is-company & {
    margin-bottom: 28px;
  }

  .is-members & {
    margin: 90px 0 100px;
    width: auto;

    @include mix.max-media(1360px){
      margin: 0;
    }
    @include mix.max-media(var.$screen-md) {
      margin-bottom: 28px;
    }
  }

  p {
    padding: 0 19px 0 21px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }

    @include mix.max-media(var.$screen-md) {
      padding: 0 11px 0 7px;
      font-size: 30px;
    }

    &.is-font-size-sm {
      font-size: 30px;
      @include mix.max-media(var.$screen-md) {
        padding: 0 11px 0 7px;
        height: 45px;
        font-size: 20px;
      }
    }

    &.is-sp-height-sm {
      @include mix.max-media(var.$screen-md) {
        padding: 0 11px 0 7px;
        height: 40px;
        font-size: 16px;
      }
    }
  }
}

//.page-about-block-foot {
//  width: 100%;
//  display: flex;
//  flex-flow: row wrap;
//  justify-content: space-between;
//  align-items: flex-end;
//
//  .is-reverse & {
//    @include mix.min-media(var.$screen-lg-min) {
//      flex-flow: row-reverse;
//    }
//  }
//}

.page-about-block-text-unit {
  padding: 44px 50px 0 0;
  min-height: 190px;
  background: #fff;
  @include mix.max-media(var.$screen-md) {
    width: 100%;
    padding: 33px 27px 0 0;
    min-height: auto;
  }

  .is-reverse:not(.is-members)  & {
    width: 440px;
    margin: 0 0 0 auto;
    @include mix.min-media(var.$screen-lg-min) {
      padding: 50px 0 0 50px;
    }
  }

  p {
    width: 344px;
    @include mix.max-media(var.$screen-md) {
      width: 100%;
    }
  }
}

.page-about-block-btn-unit {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  .is-reverse & {
    justify-content: flex-start;
  }
}


//  @ page-about-block 
// ----------------------------------------

//.page-about-block {
//  position: relative;
//
//  &:not(:last-child) {
//    margin-bottom: 100px;
//    @include mix.max-media(var.$screen-md){
//      margin-bottom: 68px;
//    }
//  }
//
//  &:before {
//    content: "";
//    display: block;
//    width: 100vw;
//    height: 446px;
//    margin: 0 calc(50% - 50vw - 23.52vw) 0 0;
//    background: var.$brand-main-lighter;
//    position: absolute;
//    top: 54px;
//    right: 0;
//    z-index: -1;
//    @include mix.max-media(var.$screen-md){
//      margin: 0 calc(50% - 50vw - 26.66vw) 0 0;
//      height: 491px;
//      top: 20px;
//    }
//  }
//
//  &.is-reverse {
//
//    @include mix.min-media(var.$screen-lg-min){
//      &:before {
//        margin: 0 0 0 calc(50% - 50vw - 23.52vw);
//        right: auto;
//        left: 0;
//      }
//    }
//  }
//}
//
//.page-about-block-heading {
//  margin: 0 0 172px;
//  font-family: var.$font-family-roboto;
//  color: rgba(0, 0, 0, 0);;
//  font-size: 107px;
//  -webkit-text-stroke: 1px var.$brand-main;
//  letter-spacing: .2em;
//  hyphens: manual;
//
//  @include mix.max-media(var.$screen-md) {
//    margin-bottom: 30px;
//    font-size: 50px;
//  }
//
//  .is-reverse & {
//    @include mix.min-media(var.$screen-lg-min){
//      margin: 0 0 172px 0 ;
//      text-align: right;
//    }
//  }
//
//  span {
//    hyphens: auto;
//  }
//}
//
//.page-about-block-wrap {
//  width: 100%;
//  position: relative;
//  display: flex;
//  justify-content: space-between;
//  align-items: flex-end;
//
//  @include mix.max-media(var.$screen-mlg){
//    align-items: flex-start;
//    flex-flow: column;
//    gap: 40px 0;
//  }
//
//  @include mix.max-media(var.$screen-md){
//    display: block;
//  }
//
//  .is-reverse & {
//    display: flex;
//    flex-flow: row-reverse;
//    align-items: flex-end;
//    @include mix.max-media(var.$screen-mlg){
//      flex-flow: column;
//    }
//
//    @include mix.max-media(var.$screen-md){
//      display: block;
//    }
//  }
//}
//
//.page-about-block-thumb {
//  width: 91.54%;
//  height: 442px;
//  position: absolute;
//  top: -92px;
//  right: 0;
//  z-index: -1;
//
//  @include mix.max-media(var.$screen-md){
//    margin: 0 calc(50% - 50vw) 0 6.66vw;
//    width: calc(100vw - 12vw);
//    height: 200px;
//    position: static;
//  }
//
//  .is-reverse & {
//    @include mix.min-media(var.$screen-lg-min){
//      right: auto;
//      left: 0;
//    }
//  }
//
//  img {
//    width: 100%;
//    height: 100%;
//    object-fit: cover;
//  }
//}
//
//.page-about-block-content {
//  @include mix.max-media(var.$screen-md){
//    margin-top: -22px;
//  }
//
//  .is-reverse & {
//    @include mix.min-media(var.$screen-lg-min){
//      margin-right: 46px;
//    }
//  }
//}
//
//.page-about-block-title {
//  width: 100%;
//  margin: 0 0 121px;
//  display: flex;
//  flex-flow: row nowrap;
//  flex-flow: column;
//  gap: 10px;
//  align-items: flex-start;
//  @include mix.max-media(var.$screen-md){
//    margin-bottom: 40px;
//  }
//
//  .is-reverse & {
//    @include mix.min-media(var.$screen-lg-min){
//      justify-content: right;
//    }
//  }
//
//  p {
//    padding: 0 19px 0 21px;
//    height: 60px;
//    color: #fff;
//    font-family: var.$font-family-tukushi;
//    font-size: 40px;
//    font-weight: 600;
//    background: var.$brand-main;
//    line-height: 1;
//    display: flex;
//    flex-flow: row nowrap;
//    justify-content: center;
//    align-items: center;
//    white-space: nowrap;
//    @include mix.max-media(var.$screen-mlg) {
//      font-size: 32px;
//    }
//    @include mix.max-media(var.$screen-md) {
//      padding: 0 11px 0 7px;
//      font-size: 30px;
//    }
//
//    &.is-font-size-sm {
//      font-size: 30px;
//      @include mix.max-media(var.$screen-md){
//        padding: 0 11px 0 7px;
//        height: 45px;
//        font-size: 20px;
//      }
//    }
//  }
//}
//
//.page-about-block-foot{
//  width: 100%;
//  display: flex;
//  flex-flow: row wrap;
//  justify-content: space-between;
//  align-items: flex-end;
//
//  .is-reverse & {
//    @include mix.min-media(var.$screen-lg-min){
//      flex-flow: row-reverse;
//    }
//  }
//}
//
//.page-about-block-text-unit {
//  padding: 44px 50px 0 0;
//  width: 100%;
//  min-height: 190px;
//  background: #fff;
//  @include mix.max-media(var.$screen-md){
//    padding: 33px 27px 0 0;
//    margin-bottom: 30px;
//    min-height: auto;
//    max-width: none;
//  }
//
//  .is-reverse & {
//    width: 440px;
//    margin: 0 0 0 auto;
//    @include mix.min-media(var.$screen-lg-min){
//      padding: 50px 0 0 50px;
//    }
//  }
//
//  p {
//    width: 344px;
//  }
//}
//
//.page-about-block-btn-unit {
//  //width: 100%;
//  display: flex;
//  flex-flow: row wrap;
//  justify-content: flex-end;
//  gap: 20px 30px;
//
//  @include mix.max-media(var.$screen-lg){
//    justify-content: flex-start;
//  }
//  @include mix.max-media(var.$screen-md){
//    gap: 10px;
//  }
//
//  .is-reverse &{
//    @include mix.min-media(var.$screen-lg-min){
//      width: 100%;
//      justify-content: flex-start;
//    }
//  }
//}