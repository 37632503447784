@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page team
// ============================================================

//  @ crossTalk
// ----------------------------------------

.page-team-crossTalk-section {
  padding-top: 70px;
  margin-bottom: 83px;
}

.page-team-crossTalk-kv {
  margin: 0 0 28px;
  position: relative;
  @include mix.max-media(var.$screen-md) {
    margin: 0 calc(50% - 50vw) 16px;
    width: 100vw;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 15, 56, 0) 70%, rgba(0, 15, 56, .3) 95%, rgba(0, 15, 56, .6) 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    object-fit: contain;
  }

  &__elm {
    width: 100%;
    max-width: 19.19%;
    position: absolute;
    top: -50px;
    left: 39px;
    @include mix.max-media(var.$screen-md) {
      width: 159px;
      max-width: none;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__title {
    position: absolute;
    bottom: 50px;
    left: 50px;
    @include mix.max-media(var.$screen-md) {
      padding: 0 20px;
      bottom: 20px;
      left: 0;
    }

    h3 {
      font-family: var.$font-family-tukushi;
      color: #fff;
      font-size: 30px;
      @include mix.max-media(var.$screen-md) {
        font-size: 22px;
        line-height: 1.54545455;
      }
    }
  }
}

.page-team-crossTalk-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 0 0 0 50px;
  @include mix.max-media(var.$screen-md) {
    padding: 0;
    display: block;
  }
}

.page-team-crossTalk-text-unit {
  width: calc(100% - 259px - 20px);
  @include mix.max-media(var.$screen-md) {
    width: 100%;
    margin: 0 0 32px;
  }

  p {
    color: var.$brand-main-darker;
  }
}

.page-team-crossTalk-btn {
  width: 259px;
}

//  @ page-team-block 
// ----------------------------------------

.page-team-block {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 100px;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 68px;
    }
  }

  &:before {
    content: "";
    display: block;
    width: 100vw;
    height: 446px;
    margin: 0 calc(50% - 50vw - 23.52vw) 0 0;
    background: var.$brand-main-lighter;
    position: absolute;
    top: 54px;
    right: 0;
    z-index: -1;
    @include mix.max-media(var.$screen-md){
      margin: 0 calc(50% - 50vw - 26.66vw) 0 0;
      height: 491px;
      top: 20px;
    }
  }

  &.is-reverse {

    @include mix.min-media(var.$screen-lg-min){
      &:before {
        margin: 0 0 0 calc(50% - 50vw - 23.52vw);
        right: auto;
        left: 0;
      }
    }
  }
}

.page-team-block-heading {
  margin: 0 0 172px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  hyphens: manual;

  @include mix.max-media(var.$screen-md) {
    margin-bottom: 30px;
    font-size: 50px;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      margin: 0 0 172px 0 ;
      text-align: right;
    }
  }

  span {
    hyphens: auto;
  }
}

.page-team-block-wrap {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include mix.max-media(var.$screen-mlg){
    align-items: flex-start;
    flex-flow: column;
    gap: 40px 0;
  }

  @include mix.max-media(var.$screen-md){
    display: block;
  }

  .is-reverse & {
    display: flex;
    flex-flow: row-reverse;
    align-items: flex-end;
    @include mix.max-media(var.$screen-mlg){
      flex-flow: column;
    }

    @include mix.max-media(var.$screen-md){
      display: block;
    }
  }
}

.page-team-block-thumb {
  width: 91.54%;
  height: 442px;
  position: absolute;
  top: -92px;
  right: 0;
  z-index: -1;

  @include mix.max-media(var.$screen-md){
    margin: 0 calc(50% - 50vw) 0 6.66vw;
    width: calc(100vw - 12vw);
    height: 200px;
    position: static;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      right: auto;
      left: 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-team-block-content {
  @include mix.max-media(var.$screen-md){
    margin-top: -22px;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      margin-right: 46px;
    }
  }
}

.page-team-block-title {
  width: 100%;
  margin: 0 0 121px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 40px;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      justify-content: right;
    }
  }

  p {
    padding: 0 19px 0 21px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }
    @include mix.max-media(var.$screen-md) {
      padding: 0 11px 0 7px;
      font-size: 30px;
    }

    &.is-font-size-sm {
      font-size: 30px;
      @include mix.max-media(var.$screen-md){
        padding: 0 11px 0 7px;
        height: 45px;
        font-size: 20px;
      }
    }
  }
}

.page-team-block-foot{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      flex-flow: row-reverse;
    }
  }
}

.page-team-block-text-unit {
  padding: 44px 50px 0 0;
  width: 100%;
  min-height: 190px;
  background: #fff;
  @include mix.max-media(var.$screen-md){
    padding: 33px 27px 0 0;
    margin-bottom: 30px;
    min-height: auto;
    max-width: none;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      padding: 50px 0 0 50px;
    }
  }

  p {
    width: 344px;
    @include mix.max-media(var.$screen-md){
      width: 100%;
    }
  }
}

.page-team-block-btn-unit {
  //width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 20px 30px;

  @include mix.max-media(var.$screen-lg){
    justify-content: flex-start;
  }
  @include mix.max-media(var.$screen-md){
    gap: 10px;
  }

  .is-reverse &{
    @include mix.min-media(var.$screen-lg-min){
      width: 100%;
      justify-content: flex-start;
    }
  }
}