@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ container
// ============================================================

.container {
  padding: 0 0 100px;
  width: 100%;
  @include mix.max-media(var.$screen-md) {
    padding: 0 0 80px;
  }

  &__inner {
    margin: 0 auto;
    padding: 0 var.$container-lg-padding;
    width: 100%;
    max-width: var.$container-lg;

    @include mix.max-media(var.$screen-md) {
      padding: 0 var.$container-md-padding;
      max-width: 100%;
    }
  }
}

.container-tight{
  margin: 0 auto;
  width: 100%;
  max-width: 1130px;
  padding: 80px var.$container-lg-padding 0;

  @include mix.max-media(var.$screen-md) {
    padding: 30px var.$container-md-padding 0;
    max-width: 100%;
  }
}

// ============================================================
//  @ top container
// ============================================================

//  @ top about
// ----------------------------------------

.top-about-container {
  padding-top: 100px;
  background: url("../images/top/about-bg.jpg") center center no-repeat;
  background-size: cover;
  @include mix.max-media(var.$screen-md) {
    background: url("../images/top/sp_about-bg.jpg") top center no-repeat;
    background-size: contain;
    padding-top: 73px;
  }
}

//  @ top vision
// ----------------------------------------

.top-vision-container {

  &__inner {
    position: relative;
  }
}

//  @ top business info
// ----------------------------------------

.top-businessInfo-container {
  background: url("../images/top/business-info_bg-elm.png") no-repeat;
  background-size: 45.05% auto;
  background-position: left -50px bottom 15px;
  @include mix.max-media(var.$screen-md) {
    background: url("../images/top/business-info_bg-elm_sp.png") no-repeat;
    background-size: 100% auto;
    background-position: left bottom;
  }
}

//  @ top interview
// ----------------------------------------

.top-interview-container {
  margin-bottom: 0;
  padding-top: 86px;
  background: var.$brand-main;
  color: #fff;
  position: relative;
  overflow: hidden;
  @include mix.max-media(var.$screen-md) {
    padding-top: 60px;
    padding-bottom: 0;
  }

  &__inner {
    position: relative;
    z-index: 1;
  }
}

//  @ top data
// ----------------------------------------

.top-data-container {
  margin: 0 0 159px;
  padding: 74px 0 0;
  position: relative;
  @include mix.max-media(var.$screen-md) {
    margin: 0;
    padding: 74px 0 80px;
  }
}


//  @ top recruit
// ----------------------------------------

.top-recruit-container {
  position: relative;

  &:before {
    content: "";
    display: block;
    width: calc(100% - 19.48%);
    height: 100%;
    background: var.$brand-main-lighter;
    position: absolute;
    top: 0;
    left: 0;
    @include mix.max-media(var.$screen-md){
      width: 100%;
    }
  }
}

//  @ top faq
// ----------------------------------------

.top-faq-container {
  padding-bottom: 69px;
}

//  @ top news
// ----------------------------------------

.top-news-container {
  padding-top: 95px;
  background: var.$brand-main-lighter;
  @include mix.max-media(var.$screen-md) {
    padding-top: 55px;
  }
}

// ============================================================
//  @ page container
// ============================================================

.page-container {
  overflow: hidden;
}

// ============================================================
//  @ archive container
// ============================================================

.archive-container {

  &__inner {
  }
}

.archive-wrap {
  display: flex;
  flex-flow: row nowrap;
  gap: 60px;

  @include mix.max-media(var.$screen-md) {
    display: block;
  }
}

.archive-sidebar-block {
  width: 254px;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 37px;
    width: 100%;
  }
}

.archive-main-block {
  width: calc(100% - 254px - 60px);
  @include mix.max-media(var.$screen-md) {
    width: 100%;
  }
}


// ============================================================
//  @ single container
// ============================================================

.single-joblist-container {
  &__inner {
    margin: 0 auto;
    width: 100%;
    max-width: 1030px;
  }
}

.page-newgaduate-container {
  background: #F5F5F5;
}

// ============================================================
//  @ page container
// ============================================================

.page-tight-container{
  margin: 0 auto;
  padding-top: 56px;
  width: 100%;
  max-width: 1030px;
  @include mix.max-media(var.$screen-md){
    max-width: 100%;
    padding-top: 30px;
  }
}