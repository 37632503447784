@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ h-nav01
// ============================================================

.f-nav01 {

  &__body {
    display: flex;
    flex-flow: row wrap;
    gap: 18px 30px;
    @include mix.max-media(var.$screen-md) {

    }
  }

  &__item {

    > a {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      color: #fff;
      text-decoration: none;
      @include mix.text-hover();

      &:before {
        content: "";
        display: inline-block;
        margin: 0 5px 0 0;
        width: 18px;
        height: 18px;
        background: url("../images/common/f-nav01_icon.svg") center center no-repeat;
        background-size: cover;
      }
    }
  }
}

// ============================================================
//  @ f-nav02
// ============================================================

.f-nav02-unit {
  @include mix.max-media(var.$screen-md) {
    padding: 0 20px;
    border-top: 1px #4D8DCD solid;

    &:last-child {
      border-bottom: 1px #4D8DCD solid;
    }
  }
}

//  @ f-nav02-title
// ----------------------------------------

.f-nav02-title {
  margin: 0 0 13px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  @include mix.max-media(var.$screen-md) {
    margin: 0;
    padding: 20px 0;
  }
}

//  @ f-nav02
// ----------------------------------------

.f-nav02 {
  @include mix.min-media(var.$screen-lg-min) {
    padding: 0 0 28px;
    &:not(:last-child) {
      margin: 0 0 28px;
      border-bottom: 1px rgba(255, 255, 255, .3) solid;
    }
  }

  @include mix.max-media(var.$screen-md){
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    @include mix.transition-delay();

    &.is-open {
      margin-bottom: 30px;
      max-height: var(--max-height);
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__body {
    margin: 0 -10px;
    display: flex;
    flex-flow: row wrap;
    gap: 10px 0;
    @include mix.max-media(var.$screen-md) {
      margin: 0;
      flex-flow: column;
      gap: 0;
    }
  }

  &__item {
    padding: 0 10px;

    @include mix.min-media(var.$screen-lg-min) {
      &:not(:last-child) {
        border-right: 1px #fff solid;
      }
    }

    @include mix.max-media(var.$screen-md) {
      padding: 0;
      border-top: 1px rgba(255, 255, 255, .3) dashed;

      &:last-child {
        border-bottom: 1px rgba(255, 255, 255, .3) dashed;
      }
    }

    > a {
      color: #fff;
      text-decoration: none;
      @include mix.text-hover();
      @include mix.max-media(var.$screen-md) {
        display: block;
        padding: 20px 10px;
      }
    }
  }
}

//  @ f-nav03
// ----------------------------------------

.f-nav03 {
  @include mix.max-media(var.$screen-md) {
    margin: 0 0 52px;
  }

  &__body {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px 0;
    @include mix.max-media(var.$screen-md) {
      flex-flow: row wrap;
    }
  }

  &__item {

    @include mix.max-media(var.$screen-md) {
      width: calc(50%);
    }

    > a {
      color: #fff;
      font-weight: 700;
      text-decoration: none;
      white-space: nowrap;
      @include mix.text-hover();
    }
  }
}