@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page vision
// ============================================================

.page-vision-block-head-lead {
  margin: 98px 0 81px;
  color: var.$brand-main-darker;
  @include mix.max-media(var.$screen-md) {
    margin: 71px 0 81px;
  }
}

//  @ sec-visual
// ----------------------------------------

.page-vision-sec-visual {
  margin-bottom: 142px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100vw;
    height: 446px;
    margin: 0 calc(50% - 50vw - 23.52vw) 0 0;
    background: var.$brand-main-lighter;
    position: absolute;
    top: 54px;
    right: 0;
    z-index: -1;
    @include mix.max-media(var.$screen-md) {
      margin: 0 calc(50% - 50vw - 26.66vw) 0 0;
      height: 491px;
      top: 20px;
    }
  }

  .is-mb-lg & {
    margin-bottom: 210px;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 30px;
    }
  }

  &.is-reverse {

    @include mix.min-media(var.$screen-lg-min) {
      &:before {
        margin: 0 0 0 calc(50% - 50vw - 23.52vw);
        right: auto;
        left: 0;
      }
    }
  }
}

.page-vision-sec-visual-heading {
  margin: 0 0 162px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  hyphens: manual;

  @include mix.max-media(var.$screen-md) {
    margin-bottom: 30px;
    font-size: 50px;

    &.is-font-size-42px--md{
      font-size: 42px;
      line-height: 1.30952381;
    }

    &.is-font-size-48px--md{
      font-size: 48px;
      line-height: 1.30952381;
    }
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min) {
      margin: 0 91px 172px 0;
      text-align: right;
    }
  }

  span {
    hyphens: auto;
  }
}

.page-vision-sec-visual-wrap {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include mix.max-media(var.$screen-lg) {
    align-items: flex-start;
    flex-flow: column;
    gap: 40px 0;
  }

  @include mix.max-media(var.$screen-md) {
    display: block;
  }

  .is-reverse & {
    display: flex;
    flex-flow: row-reverse;
    align-items: flex-end;
    @include mix.max-media(var.$screen-lg) {
      flex-flow: column;
    }

    @include mix.max-media(var.$screen-md) {
      display: block;
    }
  }
}

.page-vision-sec-visual-thumb {
  width: 91.54%;
  height: 442px;
  position: absolute;
  top: -92px;
  right: 0;
  z-index: -1;

  @include mix.max-media(var.$screen-md) {
    margin: 0 calc(50% - 50vw) 0 6.66vw;
    width: calc(100vw - 12vw);
    height: 200px;
    position: static;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min) {
      right: auto;
      left: 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-vision-sec-visual-content {
  @include mix.max-media(var.$screen-md) {
    margin-top: -22px;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min) {
      margin-right: 46px;
    }
  }
}

.page-vision-sec-visual-title {
  width: 100%;
  margin: 0 0 121px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 0;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min) {
      justify-content: right;
    }
  }

  p {
    padding: 11px 16px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }
    @include mix.max-media(var.$screen-md) {
      padding: 0 11px 0 7px;
      font-size: 30px;
    }

    &.is-font-size-sm {
      padding: 0 19px 0 21px;
      font-size: 30px;
      @include mix.max-media(var.$screen-md) {
        padding: 0 11px 0 7px;
        height: 45px;
        font-size: 20px;
      }
    }
  }
}

//  @ list01
// ----------------------------------------

.page-vision-list01 {
  margin-bottom: 130px;
  display: flex;
  flex-flow: row wrap;
  @include mix.max-media(var.$screen-md) {
    margin: -86px 0 58px;
    flex-flow: column;
  }

  &__item {
    padding: 0 20px 31px;
    flex: 1;
    position: relative;

    @include mix.min-media(var.$screen-lg-min) {
      &:not(:last-child) {
        border-right: 1px var.$brand-gray-darker solid;
      }
    }

    @include mix.max-media(var.$screen-md) {
      margin: 0 0 20px;
      padding: 0 0 15px;

      &:not(:last-child) {
        border-bottom: 1px var.$brand-gray-darker solid;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 22px;
      height: 11px;
      background: url("../images/page-vision/list01-icon-arrow.svg") center center no-repeat;
      background-size: cover;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      @include mix.max-media(var.$screen-md) {
        width: 23px;
        height: 12px;
        position: absolute;
        top: calc(50% - 8px);
        left: auto;
        right: 0;
        transform: translate(0, -50%);
      }
    }

    > a {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      text-decoration: none;
      @include mix.text-hover();
      @include mix.max-media(var.$screen-md) {
        @include mix.max-media(var.$screen-md) {
          flex-flow: row;
        }
      }
    }
  }

  &__heading {
    margin-bottom: 23px;
    font-family: var.$font-family-roboto;
    font-weight: 700;
    text-align: center;
    @include mix.max-media(var.$screen-md) {
      margin-right: 13px;
      margin-bottom: 0;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
    }
  }

  &__title {
    margin-bottom: 4px;
    color: var.$brand-main;
    font-size: 19px;
    line-height: 1;
    letter-spacing: var.$font-letter-spacing;
    @include mix.max-media(var.$screen-md) {
      font-size: 12px;
    }
  }

  &__num {
    color: var.$brand-main;
    font-size: 55px;
    line-height: 1;
    letter-spacing: var.$font-letter-spacing;
    @include mix.max-media(var.$screen-md) {
      font-size: 38px;
    }
  }

  &__text {
    text-align: center;
    @include mix.max-media(var.$screen-md) {
      margin-top: -2px;
      text-align: left;
    }

    p {
      color: var.$brand-main-darker;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.95;
      letter-spacing: .05em;
      @include mix.max-media(var.$screen-md) {
        font-size: 16px;
        line-height: 1.8125;
      }
    }
  }
}

//  @ block
// ----------------------------------------

.page-vision-section {
  &:not(:last-child) {
    margin-bottom: 82px;
  }
}

.page-vision-block {
  &:not(:last-child) {
    margin-bottom: 130px;
    @include mix.max-media(var.$screen-md) {
      margin-bottom: 105px;
    }
  }
}

.page-vision-block-head {
  margin-bottom: 36px;

  &__heading {
    margin-bottom: 40px;
    position: relative;

  }

  &__elm {
    font-family: var.$font-family-roboto;
    color: #EAF3FA;
    font-weight: 700;
    position: absolute;
    top: calc(50% - 18px);
    left: 6px;
    transform: translate(0, -50%);
    z-index: -1;
    @include mix.max-media(var.$screen-md) {
      top: calc(50% - 0px);
    }
  }

  &__elm-text {
    color: #EAF3FA;
    font-size: 30px;
    line-height: 1;
    letter-spacing: var.$font-letter-spacing;
    @include mix.max-media(var.$screen-md) {
      font-size: 25px;
    }
  }

  &__elm-num {
    color: #EAF3FA;
    font-size: 100px;
    line-height: 1;
    letter-spacing: var.$font-letter-spacing;
    @include mix.max-media(var.$screen-md) {
      font-size: 83px;
    }
  }

  &__title {
    padding-left: 65px;
    font-family: var.$font-family-tukushi;
    color: var.$brand-main-darker;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5;
    @include mix.max-media(var.$screen-md) {
      padding-left: 52px;
      font-size: 20px;
      white-space: nowrap;
    }
  }

  &__lead {
    color: var.$brand-main-darker;
  }
}

.page-vision-block-list01 {
  margin-bottom: 44px;
  display: flex;
  flex-flow: row wrap;
  gap: 47px 20px;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 46px;
    gap: 37px 0;
  }

  &__item {
    width: calc(33.33% - 20px * 2 / 3);
    @include mix.max-media(var.$screen-md) {
      width: 100%;
    }
  }

  &__thumb {
    margin-bottom: 28px;
  }

  &__content {
    color: var.$brand-main-darker;
  }

  &__title {
    margin-bottom: 22px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .05em;
  }

  ul {
    padding-left: 17px;

    li {
      position: relative;
      line-height: 1.2;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &:before {
        content: "";
        display: block;
        width: 3px;
        height: 3px;
        background: var.$brand-main-darker;
        position: absolute;
        top: 50%;
        left: -11px;
        transform: translate(0, -50%);
      }
    }
  }
}

.page-vision-block-btn-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.page-vision-block-heading02 {
  margin-bottom: 15px;
  padding: 11px 16px 12px;
  width: 100%;
  background: var.$brand-main-darker;
  color: #fff;
  font-family: var.$font-family-tukushi;
  font-size: 26px;
  font-weight: 600;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 12px;
    font-size: 17px;
    line-height: 1.52941176;
  }
}

.page-vision-block-text-unit {
  margin-bottom: 40px;
  color: var.$brand-main-darker;
}

.page-vision-block-box01 {
  padding: 40px;
  background: #F0F5F7;
  @include mix.max-media(var.$screen-md) {
    padding: 28px 32px;
  }

  &.is-padding-md {
    padding: 20px 30px 10px;
    @include mix.max-media(var.$screen-md){
      padding: 17px 32px 20px;
    }
  }

  &__title {
    margin-bottom: 28px;
    padding: 0 0 0 20px;
    border-left: 3px var.$brand-main solid;
    color: var.$brand-main-darker;
    font-family: var.$font-family-tukushi;
    font-size: 18px;
    font-weight: 500;
    @include mix.max-media(var.$screen-md) {
      margin-bottom: 18px;
    }
  }

  &__text-unit {
    color: var.$brand-main-darker;
  }
}

.page-vision-block-img {
  margin-bottom: 40px;
  padding-bottom: 37px;
  @include mix.max-media(var.$screen-md) {
    height: 608px;
    overflow-y: scroll;

    img {
      width: auto;
      height: 100%;
    }
  }
}

//  @ page-business-foot-nav
// ----------------------------------------

.page-vision-foot-nav-container {
  overflow: hidden;

  &__inner {
    margin: 0 auto;
    padding: 0 var.$container-lg-padding 68px;
    width: 100%;
    max-width: var.$container-lg;
    position: relative;
    @include mix.max-media(var.$screen-md){
      padding: 0 var.$container-md-padding 35px;
    }

    &:before {
      content: "";
      display: block;
      width: 100vw;
      margin: 0 calc(50vw - 50%) 0 0;
      height: 100%;
      background: #EAF3FA;
      position: absolute;
      top: 25px;
      left: 100px;
      z-index: -1;
      @include mix.max-media(var.$screen-md){
        top: 78px;
        left: 71px;
      }
    }
  }
}

.page-vision-foot-nav-container-title {
  margin-bottom: 10px;
  font-family: var.$font-family-roboto;
  font-size: 46px;
  color: var.$brand-main;
  letter-spacing: var.$font-letter-spacing;
  font-weight: 700;
  @include mix.max-media(var.$screen-md){
    line-height: 1.19565217;
    margin-bottom: 1px;
  }

}

.page-vision-foot-nav-container-lead {
  margin-bottom: 10px;
  font-size: 20px;
  color: var.$brand-main-darker;
  font-weight: 700;
}

.page-vision-foot-nav {
  display: flex;
  flex-flow: row wrap;
  @include mix.max-media(var.$screen-md) {
    display: block;
  }

  &__item {
    width: calc(33.33%);
    position: relative;
    @include mix.max-media(var.$screen-md) {
      width: 100%;
    }


    a {
      color: #fff;
      display: block;
      @include mix.text-hover();

      &:before {
        background: linear-gradient(to bottom, rgba(0, 15, 56, 0) 60%, rgba(0, 15, 56, 0.3) 85%, rgba(0, 15, 56, 0.6) 100%);
        content: "";
        display: block;
        height: 100%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1;
      }
    }
  }

  &__content {
    padding: 28px 32px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    @include mix.max-media(var.$screen-mlg) {
      padding: 20px 16px;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    @include mix.max-media(var.$screen-mlg) {
      margin-bottom: 2px;
      font-size: 18px;
    }
  }

  &__lead {
    color: #fff;
    font-family: var.$font-family-roboto;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .2em;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 12px;
    }
  }
}