@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ h-nav
// ============================================================

.h-nav {
  @include mix.max-media(var.$screen-md) {
    width: 100%;
    height: 100%;
    background: var.$brand-main;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, -100%);
    z-index: var.$zindex-drawer;
    transition: transform .6s ease;;

    .is-viewNav & {
      transform: translate(0, 0);
    }


  }

  &__body {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    //margin: 0 -14px;
    gap: 10px 27px;
    @include mix.max-media(var.$screen-md) {
      flex-flow: column;
      justify-content: center;
      align-items: center;
      gap: 30px 0;
    }
  }

  &__item {

    > a {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      @include mix.text-hover();
    }
  }
}