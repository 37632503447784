@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ pager
// ============================================================

.pager {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  @include mix.max-media(var.$screen-md){
    top: 0;
    transform: translate(0,0);
  }

  &__prev, &__next {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    a {
      display: block;
      font-family: var.$font-family-roboto;
      color: var.$brand-main;
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      letter-spacing: var.$font-letter-spacing;
      position: relative;

      &:before {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        background: url("../images/single/pager-icon-arrow.svg") center center no-repeat;
        background-size: cover;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  &__prev {
    left: 0;
    a {
      padding-left: 20px;

      &:before {
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        left: 0;
      }
    }
  }

  &__next {
    right: 0;
    a {
      padding-right: 20px;

      &:before {
        right: 0;
        transform: translate(0, -50%) scale(-1, 1);
      }
    }
  }
}