@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page message
// ============================================================

.page-company-section {
  margin: 60px auto 42px;
  max-width: 1026px;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 0;
  }
}

//  @
// ----------------------------------------

.page-company-img01 {
  width: 335px;
}

.page-company-btn-group {
  margin-bottom: 56px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 12px 40px;
  @include mix.max-media(var.$screen-md){
    justify-content: left;
  }
}

.page-company-list01 {
  margin-bottom: 90px;
  display: flex;
  flex-flow: row wrap;
  gap: 78px 10px;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 85px;
    gap: 34px 0;
  }

  &__item {
    width: calc(33.33% - 10px * 2 / 3);
    display: flex;
    flex-flow: column wrap;
    @include mix.max-media(var.$screen-md){
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 7px;
    padding-left: 26px;
    font-size: 18px;
    color: var.$brand-main-darker;
    line-height: 1.55555556;
    position: relative;

    &:before {
      background: #333;
      content: "";
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      top: 12px;
      width: 15px;
    }
  }

  &__thumb {
    margin-bottom: 18px;
  }

  &__text-unit{
    margin-bottom: 20px;
  }

  &__btn {
    margin: auto 0 0;
    padding: 0 20px;
    width: 100%;
    height: 47px;
    background: var.$brand-main;
    border-radius: 9999px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}