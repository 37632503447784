@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page business information
// ============================================================

//  @ page-business-block 
// ----------------------------------------

.page-business-block {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 100px;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 68px;
    }
  }

  &:before {
    content: "";
    display: block;
    width: 100vw;
    height: 446px;
    margin: 0 calc(50% - 50vw - 23.52vw) 0 0;
    background: var.$brand-main-lighter;
    position: absolute;
    top: 54px;
    right: 0;
    z-index: -1;
    @include mix.max-media(var.$screen-md){
      margin: 0 calc(50% - 50vw - 26.66vw) 0 0;
      height: 491px;
      top: 20px;
    }
  }

  &.is-reverse {

    @include mix.min-media(var.$screen-lg-min){
      &:before {
        margin: 0 0 0 calc(50% - 50vw - 23.52vw);
        right: auto;
        left: 0;
      }
    }
  }
}

.page-business-block-heading {
  margin: 0 0 172px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  hyphens: manual;

  @include mix.max-media(var.$screen-md) {
    margin-bottom: 30px;
    font-size: 50px;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      margin: 0 91px 172px 0 ;
      text-align: right;
    }
  }

  span {
    hyphens: auto;
  }
}

.page-business-block-wrap {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include mix.max-media(var.$screen-lg){
    align-items: flex-start;
    flex-flow: column;
    gap: 40px 0;
  }

  @include mix.max-media(var.$screen-md){
    display: block;
  }

  .is-reverse & {
    display: flex;
    flex-flow: row-reverse;
    align-items: flex-end;
    @include mix.max-media(var.$screen-lg){
      flex-flow: column;
    }

    @include mix.max-media(var.$screen-md){
      display: block;
    }
  }
}

.page-business-block-thumb {
  width: 91.54%;
  height: 442px;
  position: absolute;
  top: -92px;
  right: 0;
  z-index: -1;

  @include mix.max-media(var.$screen-md){
    margin: 0 calc(50% - 50vw) 0 6.66vw;
    width: calc(100vw - 12vw);
    height: 200px;
    position: static;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      right: auto;
      left: 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-business-block-content {
  @include mix.max-media(var.$screen-md){
    margin-top: -22px;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      margin-right: 46px;
    }
  }
}

.page-business-block-title {
  width: 100%;
  margin: 0 0 121px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 40px;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      justify-content: right;
    }
  }

  p {
    padding: 0;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }
    @include mix.max-media(var.$screen-md) {
      padding: 0 11px 0 7px;
      font-size: 30px;
    }

    &.is-font-size-sm {
      padding: 0 19px 0 21px;
      font-size: 30px;
      @include mix.max-media(var.$screen-md){
        padding: 0 11px 0 7px;
        height: 45px;
        font-size: 20px;
      }
    }
  }
}

.page-business-block-foot{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      flex-flow: row-reverse;
    }
  }
}

.page-business-block-text-unit {
  padding: 44px 50px 0 0;
  width: 100%;
  min-height: 190px;
  max-width: 395px;
  background: #fff;
  @include mix.max-media(var.$screen-md){
    padding: 33px 27px 0 0;
    margin-bottom: 30px;
    min-height: auto;
    max-width: none;
  }

  .is-reverse & {
    @include mix.min-media(var.$screen-lg-min){
      padding: 50px 0 0 50px;
    }
  }
}

.page-business-block-btn-unit {
  //width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 20px 30px;

  @include mix.max-media(var.$screen-lg){
    justify-content: flex-start;
  }
  @include mix.max-media(var.$screen-md){
    gap: 10px;
  }

  .is-reverse &{
    @include mix.min-media(var.$screen-lg-min){
      width: 100%;
      justify-content: flex-start;
    }
  }
}

//  @ page-business-foot-nav
// ----------------------------------------

.page-business-foot-nav {
  display: flex;
  flex-flow: row nowrap;
  @include mix.max-media(var.$screen-md){
    display: block;
  }

  &__item{
    width: calc(100% / 5);
    position: relative;
    @include mix.max-media(var.$screen-md){
      width: 100%;
    }


    a {
      color: #fff;
      display: block;
      @include mix.text-hover();

      &:before {
        background: linear-gradient(to bottom, rgba(0, 15, 56, 0) 60%, rgba(0, 15, 56, 0.3) 85%, rgba(0, 15, 56, 0.6) 100%);
        content: "";
        display: block;
        height: 100%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1;
      }
    }
  }

  &__content {
    padding: 28px 32px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    @include mix.max-media(var.$screen-mlg){
      padding: 20px 16px;
    }
  }

  &__title{
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    @include mix.max-media(var.$screen-mlg){
      margin-bottom: 2px;
      font-size: 18px;
    }
  }

  &__lead {
    color: #fff;
    font-family: var.$font-family-roboto;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .2em;
    @include mix.max-media(var.$screen-mlg){
      font-size: 12px;
    }
  }
}