@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ drawer
// ============================================================

.drawer-toggle {
  display: none;

  @include mix.max-media(var.$screen-md) {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    position: relative;
    z-index: var.$zindex-h-toggle;
  }
  
  span {
    content: "";
    display: block;
    height: 2px;
    background: #fff;

    &:nth-child(1){
      width: 38px;
      margin: 0 0 7px;
      opacity: 1;
      transition: opacity .6s ease;;

      .is-viewNav & {
        opacity: 0;
      }
    }

    &:nth-child(2){
      width: 42px;
      margin: 0 0 6px;
      transform: rotate(0) translateY(0);
      transition: transform .6s ease;;

      .is-viewNav & {
        transform: rotate(-15deg) translateY(-4px);
      }
    }
  }

  &__text {
    @include mix.max-media(var.$screen-md) {
      font-family: var.$font-family-roboto;
      color: #fff;
      font-size: 9px;
      font-weight: 700;
      letter-spacing: .2em;
    }
  }
}