@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ list01
// ============================================================

.list01 {
  li {
    color: var.$brand-main-darker;
    position: relative;
    padding-left: 10px;
    line-height: 1.5;

    &:not(:last-child){
      margin-bottom: 6px;
    }

    &:before {
      content: "";
      width: 3px;
      height: 3px;
      background: var.$brand-main-darker;
      border-radius: 50%;
      position: absolute;
      top: 9px;
      left: 0;
    }
  }
}

// ============================================================
//  @ num-list01
// ============================================================

.num-list01 {
  counter-reset: num;
  list-style-type: none;

  li{
    position: relative;
    color: var.$brand-main-darker;

    &:before {
      margin-right: 4px;
      counter-increment: num;
      content: counter(num) '.';
      color: var.$brand-main-darker;
      font-size: 15px;
    }
  }
}


// ============================================================
//  @ ol-list01
// ============================================================

.ol-list01 {
  margin-bottom: 55px;
  counter-reset: num;
  list-style-type: none;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 48px;
  }

  &__item {
    position: relative;
    line-height: 1.5em;
    padding: 6px 14px 13px 36px;
    color: var.$brand-main-darker;
    @include mix.max-media(var.$screen-md){
      line-height: 2;
      @include mix.max-media(var.$screen-md){
        padding: 6px 14px 7px 32px;
      }
    }

    &:before {
      counter-increment: num;
      content: counter(num);
      width: 24px;
      height: 24px;
      background: var.$brand-main;
      border-radius: 50%;
      color: #fff;
      font-family: var.$font-family-roboto;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 25px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      position: absolute;
      top: 5px;
      left: 0;
      @include mix.max-media(var.$screen-md){
        top: 9px;
        left: -2px;
      }
    }
  }
}

// ============================================================
//  @ list02
// ============================================================

.list02 {
  display: flex;
  flex-flow: row wrap;
  gap: 30px 10px;
  @include mix.max-media(var.$screen-md){
    gap: 20px 0;
  }

  &__item {
    width: calc(33.33% - 10px * 2 / 3);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    @include mix.max-media(var.$screen-md){
      width: 100%;
    }
  }

  &__num {
    font-family: var.$font-family-roboto;
    color: var.$brand-main;
    font-size: 46px;
    letter-spacing: .1em;
    line-height: 1;
    @include mix.max-media(var.$screen-md){
      font-size: 36px;
    }
  }

  &__text {
    margin: 0 0 0 -14px;
    padding: 4px 0 4px 30px;
    background: #fff;
    border-left: 1px var.$brand-main solid;
    font-family: var.$font-family-tukushi;
    color: var.$brand-main-darker;
    font-size: 26px;
    @include mix.max-media(var.$screen-md){
      margin: 0 0 0 -10px;
      padding: 6px 0 6px 16px;
      font-size: 17px;
      background: linear-gradient(90deg, #fff 0%, #fff 10%, transparent 10%, transparent 100%);

      .is-vision & {
        padding: 0 0 0 16px;
        line-height: 1.58823529;
      }
    }
  }
}