@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

@each $media, $blakepoint in var.$blakepoints {
  .hidden--#{$media}{
    @media screen and (max-width: #{$blakepoint}) {
      display: none!important;
    }
  }
}

@each $media, $blakepoint in var.$blakepoints {
  .visible--#{$media}{
    display: none!important;
    @media screen and (max-width: #{$blakepoint}) {
      display: block!important;
    }
  }
}

.dis--block{
  display: block;
}

.dis--inline_block{
  display: inline-block;
}

.dis--flex{
  display: flex;
}