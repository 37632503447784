@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as func;

// ============================================================ //
// @ sidebar
// ============================================================ //

.archive-sidebar{
  position: sticky;
  top: 144px;
}