@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ singe joblist
// ============================================================

//  @ .single-joblist-head
// ----------------------------------------

.single-joblist-head {
  margin-bottom: 64px;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 27px;
  }
}

.single-joblist-heading01 {
  margin-bottom: 20px;
  color: var.$brand-main-darker;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.4;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.single-joblist-tag-list {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  @include mix.max-media(var.$screen-md) {
    gap: 5px;
  }

  li {
    > a {
      padding: 6px 18px;
      display: inline-block;
      background: #F7F7F7;
      border: 1px #EEE solid;
      border-radius: 9999px;
      color: var.$text-color;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      @include mix.max-media(var.$screen-md) {
        padding: 4px 10px 5px;
        font-size: 10px;
      }
    }
  }
}

.single-joblist-thumb {
  margin-top: 30px;
  @include mix.max-media(var.$screen-md){
    margin-top: 15px;
  }
}

//  @ single-joblist heading02
// ----------------------------------------

.single-joblist-heading02 {
  margin-bottom: 30px;

  &__title {
    color: var.$brand-main;
    margin-bottom: 6px;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: var.$font-letter-spacing;
  }

  &__lead {
    color: var.$brand-main-darker;
    font-size: 20px;
    font-weight: 700;
  }
}

//  @ single-joblist table
// ----------------------------------------

.single-joblist-table {
  margin-top: 62px;
  margin-bottom: 100px;
  width: 100%;
  border-top: 1px #DBDBDB solid;
  font-size: 15px;
  @include mix.max-media(var.$screen-md) {
    margin-top: 15px;
    margin-bottom: 64px;
    font-size: 14px;
  }

  tr {
    border-bottom: 1px #DBDBDB solid;
  }

  th, td {
    padding: 40px 0;
    color: var.$brand-main-darker;
    @include mix.max-media(var.$screen-md) {
      display: block;
    }
  }

  th {
    width: 222px;
    font-weight: 700;
    text-align: left;
    @include mix.max-media(var.$screen-md) {
      padding: 20px 0 17px;
      width: 100%;
    }
  }

  td {
    line-height: 2;
    @include mix.max-media(var.$screen-md) {
      padding: 0 0 15px;
    }

    p {
      font-size: 15px;
      @include mix.max-media(var.$screen-md) {
        font-size: 14px;
      }
    }
  }
}

//  @ single-joblist test-unit
// ----------------------------------------

.single-joblist-text-unit {
  margin-bottom: 33px;
  padding-bottom: 40px;
  border-bottom: 1px #DBDBDB solid;
  color: var.$brand-main-darker;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 24px;
  }
}

//  @ single-joblist-btn-group
// ----------------------------------------

.single-joblist-btn-group {
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 0 40px;
}