@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ sec about
// ============================================================

.top-about-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  @include mix.max-media(var.$screen-mlg) {
    flex-flow: column;
  }
}


//  @ top-about-menu
// ----------------------------------------

.top-about-menu {
  margin-right: 40px;
  width: calc(100% - 475px - 40px);
  max-width: 690px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 10px 10px;
  @include mix.max-media(var.$screen-mlg) {
    margin: 0;
    width: 100%;
    max-width: 100%;
    order: 2;
  }

  @include mix.max-media(var.$screen-md) {
    margin: 0 0 40px;
  }

  &__item {
    position: relative;

    a {
      display: block;
      width: 100%;
      position: relative;
      overflow: hidden;

      &:before {
        width: 100%;
        height: 100%;
        content: "";
        display: block;
        background: linear-gradient(to bottom, rgba(0, 15, 56, 0) 60%, rgba(0, 15, 56, 0.3) 85%, rgba(0, 15, 56, 0.6) 100%);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }

      &:hover img {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }

    &--message {
      width: 100%;
      @include mix.max-media(var.$screen-mlg) {
        width: calc(33% - 15px / 3);
      }
      @include mix.max-media(var.$screen-md) {
        width: 100%;
      }

      a {
        padding-top: 43.48%;
        @include mix.max-media(var.$screen-md) {
          padding-top: 70.15%;
        }
      }

    }

    &--services, &--company {
      width: calc(50% - 5px);
      @include mix.max-media(var.$screen-mlg) {
        width: calc(33% - 15px / 3);
      }
      @include mix.max-media(var.$screen-md) {
        width: 100%;
      }

      a {
        padding-top: 88.24%;
        @include mix.max-media(var.$screen-mlg) {
          padding-top: 43.48%;;
        }
        @include mix.max-media(var.$screen-md) {
          padding-top: 70.15%;
        }
      }

    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include mix.transition-delay(.6s);
    }
  }

  &__content {
    padding: 0 0 24px 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    @include mix.max-media(var.$screen-mlg) {
      padding: 4px 10px;
    }

    @include mix.max-media(var.$screen-md) {
      padding: 0 0 10px 22px;
    }

    .top-about-menu__item--services &,
    .top-about-menu__item--company & {
      padding: 0 0 24px 30px;
      @include mix.max-media(var.$screen-mlg) {
        padding: 4px 10px;
      }

      @include mix.max-media(var.$screen-md) {
        padding: 0 0 10px 22px;
      }
    }
  }

  &__title {
    margin: 0 0 6px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    @include mix.max-media(var.$screen-mlg) {
      margin: 0;
      font-size: 16px;
    }

    @include mix.max-media(var.$screen-md) {
      font-size: 24px;
    }
  }

  &__text {
    color: #fff;
    font-family: var.$font-family-roboto;
    font-weight: 700;
    letter-spacing: .2em;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 12px;
    }
    @include mix.max-media(var.$screen-md) {
      font-size: 18px;
    }
  }
}

//  @ top-about-content
// ----------------------------------------

.top-about-content {
  margin: 46px 0 0;
  width: 475px;
  @include mix.max-media(var.$screen-mlg) {
    margin: 0 0 40px;
    width: 100%;
    order: 1;
  }

  @include mix.max-media(var.$screen-md) {
    margin: 0 0 30px;
  }

  &__inner {
    display: flex;
    flex-flow: column nowrap;
  }
}

.top-about-title {
  margin: 0 0 32px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;

  @include mix.max-media(var.$screen-md) {
    font-size: 55px;
  }
}

.top-about-lead {
  margin: 0 0 37px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;

  p {
    padding: 0 19px 0 21px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }
    @include mix.max-media(var.$screen-md) {
      padding: 0 11px 0 7px;
      font-size: 30px;
    }
    white-space: nowrap;
  }
}


.top-about-text-unit {
  margin: 0 0 36px;
  color: #103B65;
  @include mix.max-media(var.$screen-md) {
    margin: 0;
  }
}

.top-about-btn {
  @include mix.max-media(var.$screen-md) {
    order: 3;
  }
}


// ============================================================
//  @ sec vision
// ============================================================

.top-vision-title {
  margin: 0;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  position: absolute;
  top: -50px;
  left: 50px;
  z-index: 1;

  @include mix.max-media(var.$screen-md) {
    top: -25px;
    left: var.$container-md-padding;
    font-size: 55px;
  }
}

.top-vision-kv {
  width: 100vw;
  margin: 0 calc(50% - 50vw);
}

.top-vision-content {
  margin: -38px 0 0;
}

.top-vision-lead {
  margin: 0 0 26px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;

  p {
    padding: 0 19px 0 21px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }
    @include mix.max-media(var.$screen-md) {
      padding: 0 11px 0 7px;
      font-size: 30px;
    }

    &.is-font-size-sm {
      font-size: 30px;
      @include mix.max-media(var.$screen-mlg) {
        font-size: 26px;
      }
      @include mix.max-media(var.$screen-md) {
        font-size: 20px;
      }
    }
  }
}

.top-vision-text-unit {
  margin: 0 0 36px;
}


// ============================================================
//  @ sec business info
// ============================================================

.top-businessInfo-kv {
  display: flex;
  flex-flow: row nowrap;
  gap: 14px;
  @include mix.max-media(var.$screen-md) {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    flex-flow: row wrap;
    gap: 5px;
  }

  &__item {
    width: 25%;
    @include mix.max-media(var.$screen-md) {
      width: calc(50% - 5px / 2);
    }
  }
}

.top-businessInfo-content-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  @include mix.max-media(var.$screen-md) {
    display: block;
  }
}

.top-businessInfo-content {
  margin: -50px 0 0;
  @include mix.max-media(var.$screen-md) {
    margin: -23px 0 0;
  }
}

.top-businessInfo-title {
  margin: 0 -21px 33px 0;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  line-height: 1.02;
  position: relative;
  z-index: 1;
  @include mix.max-media(var.$screen-mlg) {
    font-size: 80px;
  }

  @include mix.max-media(var.$screen-md) {
    font-size: 42px;
  }
}

.top-businessInfo-lead {
  margin: 0 0 40px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;

  p {
    padding: 0 22px 0 21px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }

    @include mix.max-media(var.$screen-md) {
      height: 55px;
      font-size: 30px;
    }

    &.is-font-size-sm {
      font-size: 30px;
      @include mix.max-media(var.$screen-mlg) {
        height: 45px;
        font-size: 26px;
      }
      @include mix.max-media(var.$screen-md) {
        font-size: 20px;
      }
    }
  }
}

// ============================================================
//  @ sec interview
// ============================================================

.top-interview-title {
  margin: 0 0 104px -4px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px #fff;
  letter-spacing: .2em;
  @include mix.max-media(var.$screen-mlg) {
    font-size: 80px;
  }
  @include mix.max-media(var.$screen-md) {
    margin: 0 0 25px;
    font-size: 55px;
  }
}

//  @ top-interview-wrap
// ----------------------------------------

.top-interview-wrap {
  margin: 0 0 168px;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  @include mix.max-media(var.$screen-md) {
    margin: 0 0 98px;
    display: block;
  }

  &:before {
    display: none;
    @include mix.max-media(var.$screen-md) {
      content: "";
      display: block;
      background: #00478E;
      margin: 0 calc(50% - 50vw);
      width: 100vw;
      height: 52.43%;
      position: absolute;
      bottom: -100px;
      right: 0;
    }
  }
}

.top-interview-content {
  margin-right: 8.38%;;
  width: 434px;
  display: flex;
  flex-flow: column nowrap;
  @include mix.max-media(var.$screen-mlg) {
    width: 350px;
  }
  @include mix.max-media(var.$screen-md) {
    margin-right: 0;
    width: 100%;
  }
}

.top-interview-slider {
  margin-top: -44px;
  margin-right: calc(50% - 50vw);
  width: calc(100% - 434px);
  position: relative;
  @include mix.max-media(var.$screen-mlg) {
    width: calc(100% - 350px);
  }
  @include mix.max-media(var.$screen-md) {
    margin-top: 0;
    padding-bottom: 100px;
    width: 100%;
  }

  &:before {
    content: "";
    display: block;
    background: #00478E;
    width: 100vw;
    margin-right: calc(50% - 50vw);
    height: calc(100% + 188px - 83px + #{min(15.7vw, 267px)});
    position: absolute;
    top: 83px;
    left: -83px;
    @include mix.max-media(var.$screen-md) {
      display: none;
    }
  }
}

.top-interview-lead {
  margin: 0 0 36px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md) {
    margin: 0 0 36px;
  }

  p {
    padding: 0 19px 0 21px;
    height: 60px;
    color: var.$brand-main;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: #fff;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }
    @include mix.max-media(var.$screen-md) {
      height: 55px;
      font-size: 30px;
    }
  }
}

.top-interview-text-unit {
  @include mix.max-media(var.$screen-md) {
    margin: 0 0 34px;
  }

  p{
    color: #fff;
  }
}

.top-interview-btn {
  margin: auto 0 0;
}

//  @ top-crossTalk-wrap
// ----------------------------------------

.top-crossTalk-wrap {

}

.top-crossTalk-kv {
  margin: 0 0 28px;
  position: relative;
  @include mix.max-media(var.$screen-md) {
    margin: 0 calc(50% - 50vw) 16px;
    width: 100vw;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 15, 56, 0) 70%, rgba(0, 15, 56, .3) 95%, rgba(0, 15, 56, .6) 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    object-fit: contain;
  }

  &__elm {
    width: 100%;
    max-width: 19.19%;
    position: absolute;
    top: -50px;
    left: 39px;
    @include mix.max-media(var.$screen-md) {
      width: 159px;
      max-width: none;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__title {
    position: absolute;
    bottom: 50px;
    left: 50px;
    @include mix.max-media(var.$screen-md) {
      bottom: 20px;
      left: 21px;
    }

    h3 {
      font-family: var.$font-family-tukushi;
      color: #fff;
      font-size: 30px;
      @include mix.max-media(var.$screen-md) {
        font-size: 22px;
        line-height: 1.54545455;
      }
    }
  }
}

.top-crossTalk-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0 50px;
  @include mix.max-media(var.$screen-md) {
    padding: 0;
    display: block;
  }
}

.top-crossTalk-text-unit {
  margin: 0 6.25% 0 0;
  @include mix.max-media(var.$screen-md) {
    margin: 0 0 32px;
  }

  p {
    color: #fff;
  }
}

// ============================================================
//  @ sec data
// ============================================================

//  @ top-data-anim
// ----------------------------------------

.top-data-anim {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;

  &__item {
    width: 100vw;
    color: #EAF3FA;
    font-weight: 700;
    font-family: var.$font-family-roboto;
    line-height: 1;
    text-align: right;
    animation: topDataAnim 8.5s infinite linear;
    transform: translate(100%, 0);
    position: absolute;
    white-space: nowrap;
    @include mix.max-media(var.$screen-md){
      animation: topDataAnim 5s infinite linear;
    }

    &:nth-child(1) {
      font-size: 233px;
      animation-delay: 0s;
      top: 4.5%;
      @include mix.media(var.$screen-mlg,1280px){
        font-size: calc(233px - 80px);
      }

      @include mix.media(var.$screen-md,var.$screen-mlg){
        font-size: calc(233px - 80px);
      }

      @include mix.max-media(var.$screen-md){
        font-size: calc(233px - 150px);
      }
    }

    &:nth-child(2) {
      font-family: var.$font-family-gothic;
      font-size: 130px;
      animation-delay: 4.4s;
      top: 16%;
      @include mix.media(var.$screen-mlg,1280px){
        font-size: calc(149px - 60px);
      }

      @include mix.media(var.$screen-md,var.$screen-mlg){
        font-size: calc(149px - 80px);
      }

      @include mix.max-media(var.$screen-md){
        font-size: calc(149px - 80px);
        top: 15%;
      }
    }

    &:nth-child(3) {
      font-family: var.$font-family-gothic;
      font-size: 115px;
      animation-delay: .5s;
      top: 38%;
      @include mix.media(var.$screen-mlg,1280px){
        font-size: calc(115px - 60px);
      }

      @include mix.media(var.$screen-md,var.$screen-mlg){
        font-size: calc(115px - 80px);
      }

      @include mix.max-media(var.$screen-md){
        font-size: calc(115px - 60px);
        top: 27%;
      }
    }

    &:nth-child(4) {
      font-family: var.$font-family-gothic;
      font-size: 130px;
      animation-delay: 4.7s;
      bottom: 25%;
      @include mix.media(var.$screen-mlg,1280px){
        font-size: calc(150px - 60px);
      }

      @include mix.media(var.$screen-md,var.$screen-mlg){
        font-size: calc(150px - 80px);
      }

      @include mix.max-media(var.$screen-md){
        font-size: calc(150px - 100px);
        top: 40%;
        bottom: auto;
      }
    }

    &:nth-child(5) {
      font-size: 149px;
      animation-delay: .8s;
      bottom: 0;
      @include mix.media(var.$screen-mlg,1280px){
        font-size: calc(149px - 60px);
      }

      @include mix.media(var.$screen-md,var.$screen-mlg){
        font-size: calc(149px - 80px);
      }

      @include mix.max-media(var.$screen-md){
        font-size: calc(149px - 100px);
        top: 50%;
        bottom: auto;
      }
    }


    @keyframes topDataAnim {
      0% {
        transform: translate(100%, 0);
      }
      100% {
        transform: translate(-100%, 0);
      }
    }
  }
}

//  @ top-data-wrap
// ----------------------------------------

.top-data-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  @include mix.max-media(var.$screen-mlg) {
    align-items: center;
  }
  @include mix.max-media(var.$screen-md) {
    flex-flow: column;
  }
}

.top-data-thumb {
  margin-right: 12.13%;
  width: calc(100% - 12.13% - 515px);
  @include mix.max-media(var.$screen-mlg) {
    margin-right: 30px;
    width: calc(100% - 30px - 451px);
  }
  @include mix.max-media(var.$screen-md) {
    width: 100%;
    margin-bottom: 20px;
  }

  &__img {
    margin: 0 auto;
    width: 95.34%;
    @include mix.max-media(var.$screen-md) {
      width: 81.33%;
    }
  }
}


.top-data-content {
  width: 515px;
  margin-top: 43px;
  @include mix.max-media(var.$screen-mlg) {
    width: 451px;
  }
  @include mix.max-media(var.$screen-md) {
    width: 100%;
    margin-top: 0;
  }
}

.top-data-title {
  margin: 0 0 32px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;

  @include mix.max-media(var.$screen-md) {
    font-size: 55px;
  }
}

.top-data-lead {
  margin: 0 0 26px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 35px;
  }

  p {
    padding: 0 19px 0 21px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }

    @include mix.max-media(var.$screen-md) {
      padding: 0 11px;
      height: 55px;
      font-size: 30px;
    }

    &.is-font-size-sm {
      font-size: 30px;
      @include mix.max-media(var.$screen-mlg) {
        font-size: 26px;
      }
      @include mix.max-media(var.$screen-md) {
        padding: 0 8px 0 11px;
        height: 45px;
        font-size: 20px;
      }
    }
  }
}

.top-data-text-unit {
  margin: 0 0 39px;
}

// ============================================================
//  @ sec recruit
// ============================================================

.top-recruit-wrap {
  margin: 0;
  padding-top: 83px;
  position: relative;
  @include mix.max-media(var.$screen-md) {
    margin: 0;
    padding-top: 58px;
  }
}

//  @ top-recruit-head
// ----------------------------------------

.top-recruit-title {
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  position: absolute;
  top: -55px;
  left: 0;

  @include mix.max-media(var.$screen-md) {
    font-size: 55px;
    top: -27px;
  }
}

.top-recruit-head {
  margin: 0 0 38px;
}

.top-recruit-lead {
  margin: 0 0 51px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 40px;
  }

  p {
    padding: 0 26px 0 21px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }
    @include mix.max-media(var.$screen-md) {
      font-size: 30px;
      height: 55px;
    }
  }
}

.top-recruit-btn-group {
  display: flex;
  flex-flow: row nowrap;
  gap: 10px 34px;
  @include mix.max-media(var.$screen-md) {
    gap: 11px;
  }
}

//  @ top-recruit-content-wrap
// ----------------------------------------

.top-recruit-content-wrap {
  display: flex;
  flex-flow: row nowrap;
  @include mix.max-media(var.$screen-md) {
    flex-flow: column-reverse;
  }
}

.top-recruit-content {
  margin-top: 79px;
  margin-right: 9.9%;
  min-width: 413px;
  display: flex;
  flex-flow: column nowrap;
  @include mix.max-media(var.$screen-mlg) {
    width: 300px;
  }
  @include mix.max-media(var.$screen-md) {
    margin: 0;
    width: 100%;
    min-width: 100%;
  }
}

.top-recruit-content-lead {
  margin: 0 0 33px;
  font-family: var.$font-family-tukushi;
  color: var.$brand-main-darker;
  font-weight: 500;
  font-size: 26px;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 32px;
    font-size: 22px;
  }
}

.top-recruit-text-unit {
  margin: 0 0 50px;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 32px;
  }
}

.top-recruit-thumb {
  //width: calc(100% - 434px - 9.11%);
  width: 100vw;
  margin-right: calc(50% - 50vw);
  position: relative;
  @include mix.max-media(var.$screen-mlg) {
    width: calc(100% - 300px - 9.11%);
  }
  @include mix.max-media(var.$screen-md) {
    margin: 0 0 49px 0;
    width: calc(100vw - #{var.$container-md-padding} - 2px);
    padding-top: 59.07%;
  }

  img {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

// ============================================================
//  @ sec faq
// ============================================================

.top-fap-thumb-wrap {
  display: flex;
  flex-flow: row nowrap;
  @include mix.max-media(var.$screen-md) {
    display: block;
  }

}

.top-fap-thumb {
  width: 50%;
  background: url("../images/top/faq-thumb.jpg") center center no-repeat;
  background-size: cover;
  @include mix.max-media(var.$screen-md) {
    width: 100%;
    padding-top: 68.27%;
  }
}

.top-faq-content {
  padding: 64px 50px 96px 10%;
  width: 50%;
  background: var.$brand-main-darker;
  @include mix.max-media(var.$screen-md) {
    padding: 21px var.$container-md-padding 79px;
    width: 100%;
  }
}

.top-faq-title {
  margin: 0 0 53px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px #fff;
  letter-spacing: .2em;

  @include mix.max-media(var.$screen-md) {
    margin-bottom: 33px;
    font-size: 55px;
  }
}

.top-faq-lead {
  margin: 0 0 31px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;

  p {
    padding: 0 26px 0 21px;
    height: 60px;
    color: var.$brand-main-darker;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: #fff;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }

    @include mix.max-media(var.$screen-md) {
      height: 55px;
      font-size: 30px;
    }
  }
}

.top-faq-text-unit {
  margin: 0 0 44px;
  max-width: 507px;

  p {
    color: #fff;
  }
}

// ============================================================
//  @ sec blog
// ============================================================

.top-blog-title {
  margin: 0 0 31px -6px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px var.$brand-main;
  letter-spacing: .2em;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 25px;
    font-size: 55px;
  }
}

.top-blog-wrap {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 80px;

  &:last-of-type{
    margin-bottom: 0;
  }

  @include mix.max-media(var.$screen-md) {
    flex-flow: column;
    margin-bottom: 40px;
  }
}

//  @ top-blog-content
// ----------------------------------------

.top-blog-content {
  width: 434px;
  display: flex;
  flex-flow: column nowrap;

  @include mix.max-media(var.$screen-md) {
    margin-bottom: 36px;
    width: 100%;
  }
}

.top-blog-lead {
  margin: 0 0 36px;
  display: flex;
  flex-flow: row nowrap;
  flex-flow: column;
  gap: 10px;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 35px;
  }

  p {
    padding: 0 26px 0 21px;
    height: 60px;
    color: #fff;
    font-family: var.$font-family-tukushi;
    font-size: 40px;
    font-weight: 600;
    background: var.$brand-main;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @include mix.max-media(var.$screen-mlg) {
      font-size: 32px;
    }
    @include mix.max-media(var.$screen-md) {
      padding: 0 11px;
      height: 55px;
      font-size: 30px;
    }
  }
}


.top-blog-list-wrap {
  width: calc(100% - 31.91% - 10.14%);
}

.top-blog-text-unit {
  margin: 0 0 30px;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 0;
  }
}

.top-blog-btn {
  margin: auto 0 0;
}

//  @ top-blog-list
// ----------------------------------------

.top-blog-list-wrap {
  margin: 0 0 0 10.14%;
  @include mix.max-media(var.$screen-md) {
    margin: 0;
    width: 100%;
  }

}

.top-blog-list {
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 40px;
  }

  &__item {
    border-bottom: 1px #CDD4D9 solid;

    &:not(:last-child) {
      margin: 0 0 32px;
    }

    a {
      display: block;
      padding: 0 0 22px;
      text-decoration: none;
      color: #103B65;
      @include mix.text-hover();
    }
  }

  &__head {
    margin: 0 0 14px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__cat {
    margin: 0 24px 0 0;
    padding: 9px 18px;
    border: 1px var.$brand-main solid;
    color: var.$brand-main;
    line-height: 1.42;
    text-transform: uppercase;
  }

  &__date {
    font-family: var.$font-family-roboto;
    color: var.$brand-main;
    font-weight: 700;
    letter-spacing: .2em;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.7;
    @include mix.max-media(var.$screen-md) {

    }
  }
}

// ============================================================
//  @ sec news
// ============================================================

.top-news-wrap {
  display: flex;
  flex-flow: row nowrap;

  @include mix.max-media(var.$screen-md) {
    flex-flow: column;
  }
}

//  @ top-news-content
// ----------------------------------------

.top-news-content {
  width: 295px;
  border-right: 1px #fff solid;
  @include mix.max-media(var.$screen-md){
    border-right: none;
  }
}

.top-news-title {
  margin: 0 0 10px;
  font-family: var.$font-family-roboto;
  color: var.$brand-main;
  font-size: 46px;
  font-weight: 700;
  letter-spacing: .2em;

  @include mix.max-media(var.$screen-md) {
    font-size: 55px;
  }
}

.top-news-lead {
  margin: 0 0 36px;
  font-size: 20px;
  font-weight: 700;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 30px;
  }
}

//  @ top-news-list
// ----------------------------------------

.top-news-list-wrap {
  width: calc(100% - 21.69%);
  padding-left: 9.19%;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 40px;
    padding: 0;
    width: 100%;
  }
}

.top-news-list {
  display: flex;
  flex-flow: column nowrap;
  gap: 43px 0;

  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0 65px;
    @include mix.max-media(var.$screen-md) {
      flex-flow: column;
      align-items: flex-start;
    }

    a {
      @include mix.text-hover();
    }
  }

  &__date {
    font-family: var.$font-family-roboto;
    color: var.$brand-main;
    font-weight: 700;
    letter-spacing: .2em;
    @include mix.max-media(var.$screen-md) {
      margin-bottom: 10px;
    }
  }

  &__title {
    a {
      color: #000000;
      font-weight: 400;
      line-height: 2;
    }
  }
}


//instagramアイコン追加のための記述

.instagram_icon{
  max-width: 250px;

  @include mix.max-media(var.$screen-md){
    margin: 0 auto;
  }

  img{
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

