@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ cta
// ============================================================

.cta-container {
  padding: 70px 0 85px;
  @include mix.max-media(var.$screen-md){
    overflow-x: hidden;
    padding: 70px var.$container-md-padding 85px;
  }
}

.cta-wrap {
  display: flex;
  flex-flow: row nowrap;

  @include mix.max-media(var.$screen-md){
    flex-flow: column;
  }
}

.cta-bg-elm {
  margin: 0 0 48px;
  font-family: var.$font-family-roboto;
  color: rgba(0, 0, 0, 0);;
  font-size: 107px;
  -webkit-text-stroke: 1px #DCE7EF;
  letter-spacing: .2em;
  line-height: 1.02;
  position: absolute;
  top: -64px;
  left: 27px;
  z-index: -1;

  @include mix.max-media(var.$screen-xlg){
    font-size:80px;
  }

  @include mix.max-media(var.$screen-mlg){
    font-size: 55px;
  }
  @include mix.max-media(var.$screen-md){
    top: -50px;
    left: -#{var.$container-md-padding};
  }

  .cta-career & {
    letter-spacing: .1em;
    hyphens: manual;
  }
}


//  @ cta-graduates
// ----------------------------------------

.cta-graduates {
  padding: 0 5.88% 0 6.47%;
  width: 50%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  @include mix.min-media(var.$screen-lg-min){
    border-right: 1px var.$brand-main-darker solid;
  }
  @include mix.max-media(var.$screen-md){
    margin-bottom: 60px;
    padding: 0 0 60px;
    width: 100%;
    border-bottom: 1px var.$brand-main-darker solid;
  }
}

//  @ cta-career
// ----------------------------------------

.cta-career {
  padding: 0 6.47% 0 5.88%;
  width: 50%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  @include mix.max-media(var.$screen-md){
    padding: 0;
    width: 100%;
  }
}

//  @ common
// ----------------------------------------

.cta-title {
  margin: 0 0 51px;
  padding: 0 27px 0 21px;
  height: 60px;
  background: #38A1C7;
  font-family: var.$font-family-tukushi;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 30px;
  }

  .cta-career & {
    background: #9557AC;
  }
}

.cta-text-unit {
  margin: 0 0 33px;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 36px;
  }
}