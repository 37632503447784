@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page message
// ============================================================

.page-privacy-section {
  margin: 78px auto 42px;
  max-width: 1026px;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 0;
  }
}

.page-privacy-block {
  &:not(:last-child){
    margin-bottom: 67px;
  }
}

.page-privacy-section-heading {
  margin-bottom: 11px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &__elm {
    font-family: var.$font-family-roboto;
    color: #EAF3FA;
    font-weight: 700;
  }

  &__elm-num {
    color: #EAF3FA;
    font-size: 100px;
    line-height: 1;
    letter-spacing: var.$font-letter-spacing;
    @include mix.max-media(var.$screen-md) {
      font-size: 83px;
    }
  }

  &__title {
    margin-left: -84px;
    font-family: var.$font-family-tukushi;
    color: var.$brand-main-darker;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5;
    @include mix.max-media(var.$screen-md) {
      margin-left: -80px;
      font-size: 20px;
    }
  }
}
