@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ kv
// ============================================================

.key-visual {
  width: 100%;
  height: 514px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  @include mix.max-media(var.$screen-md){
    height: 300px;
  }

  &__thumb {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__inner {
    margin: 0 auto;
    width: 100%;
    max-width: var.$container-lg;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;
  }

  &__content {
    margin: 20px 0 0;
    padding: 0 var.$container-lg-padding;
    width: 100%;
    flex-flow: column nowrap;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
    @include mix.max-media(var.$screen-md){
      padding: 0 18px;
    }
  }

  &__en-title{
    margin-left: -4px;
    font-family: var.$font-family-roboto;
    color: #fff;
    font-size: 80px;
    font-weight: 700;
    letter-spacing: .2em;
    line-height: 1.375;
    text-transform: uppercase;

    @include mix.max-media(var.$screen-md){
      margin: 0 0 13px 0;
      font-size: 40px;
      line-height: 1.25;
    }

    &.is-font-family-tukushi {
      font-family: var.$font-family-tukushi;
      letter-spacing: 0;
    }
  }

  &__title {
    padding: 15px 20px;
    display: inline-block;
    background: #fff;
    font-family: var.$font-family-tukushi;
    color: var.$brand-main;
    font-size: 30px;
    font-weight: 600;
    @include mix.max-media(var.$screen-md){
      padding: 12px 11px;
      font-size: 20px;
    }
  }
}