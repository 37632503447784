@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

html {
  font-family: var.$font-family-base;
  line-height: var.$line-height-base;
  color: var.$text-color;
  font-size: var.$font-size-base;
}

main {
  overflow-x: hidden;
}

p {
  color: var.$brand-main-darker;
  font-size: var.$font-size-base;
  line-height: var.$line-height-base;
  //letter-spacing: var.$font-letter-spacing;
}

img {
  width: 100%;
  height: auto;
  display: block;
  vertical-align: bottom;
}

ul {
  list-style: none;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}