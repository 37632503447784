@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================ //
// @ pagenation
// ============================================================ //

$img_path: "./../../assets/img/";

.pagination {
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 0 10px;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 40px;
  }

  a {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: var.$brand-main;
    text-decoration: none;
    @include mix.text-hover();

    &:not(.nav){
      width: 44px;
      height: 44px;
      border: 1px var.$brand-main solid;
      border-radius: 50%;
    }


    &.is-current{
      color: #fff;
      background: var.$brand-main;
      pointer-events: none;
    }


    &.prev {
      margin-right: 15px;

      svg {
        margin-right: 15px;
      }
    }


    &.next {
      margin-left: 15px;
      svg {
        margin-left: 15px;
      }
    }
  }
}