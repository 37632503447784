@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as func;

// ============================================================ //
// @ search-form
// ============================================================ //

.search-from-list {
  margin: 0 0 20px;
  padding: 0;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 17px;
  }

  &__item {

    &:not(:last-child){
      margin-bottom: 15px;
      @include mix.max-media(var.$screen-md){
        margin-bottom: 11px;
      }
    }
  }
}

.search-from-btn-group {
  @include mix.max-media(var.$screen-md){
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }
}

.search-form-reset {
  color: var.$brand-sub-main;
  @include mix.max-media(var.$screen-md){
    margin-top: 10px;
  }

  svg {
    margin-right: 10px;
  }
}