@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ page faq
// ============================================================

.page-faq-text-unit {
  margin-bottom: 60px;
  text-align: center;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 26px;
  }

  p {
    line-height: 1.8;
    @include mix.max-media(var.$screen-md){
      line-height: 1.92857143;
    }
  }
}

.page-faq-btn-group {
  margin-bottom: 70px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 12px 40px;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 40px;
    justify-content: flex-start;
  }
}

.page-faq-section {
  //margin-top: -91px;
  //padding-top: 91px;
  //@include mix.max-media(var.$screen-md){
  //  margin-top: -45px;
  //  padding-top: 45px;
  //}

  &:not(:last-child){
    margin-bottom: 90px;
    @include mix.max-media(var.$screen-md){
      margin-bottom: 60px;
    }
  }
}

.page-faq-heading01 {
  margin: 0 0 40px;
  padding: 12px 31px 10px;
  background: var.$brand-main-darker;
  font-family: var.$font-family-tukushi;
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  line-height: 1.5;
  @include mix.max-media(var.$screen-md) {
    margin-bottom: 30px;
    padding: 9px 17px;
    font-size: 20px;
  }
}

.page-faq-list {
  display: flex;
  flex-flow: row wrap;
  gap: 28px 30px;

  &__item {
    width: calc(50% - 30px / 2);
    @include mix.max-media(var.$screen-md){
      width: 100%;
    }
  }
}

.page-faq-accordion {
  overflow: hidden;
  padding: 0 0 0 27px;
  @include mix.max-media(var.$screen-md){
    padding-left: 23px;
  }

  &__title {
    padding: 16px 42px 14px;
    border-radius: 0 5px 5px 0 ;
    color: var.$brand-main-darker;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.6;
    position: relative;
    z-index: 1;
    cursor: pointer;
    @include mix.transition-delay();

    @include mix.max-media(var.$screen-md){
      padding: 11px 40px 11px 31px;
      font-size: 14px;
    }
    
    &.is-open {
      background: #F4F4F4;
    }

    &:before {
      content: "Q";
      width: 55px;
      height: 55px;
      background: var.$brand-main;
      border-radius: 50%;
      color: #fff;
      font-family: var.$font-family-roboto;
      font-size: 31px;
      line-height: 1;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: -27px;
      @include mix.max-media(var.$screen-md){
        width: 44px;
        height: 44px;
        font-size: 24px;
        left: -23px;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 10px;
      height: 5px;
      background: url("../images/page-faq/icon-under-arrow.svg") center center no-repeat;
      background-size: cover;
      position: absolute;
      top: 50%;
      right: 26px;
      transform: translate(0,-50%);
      @include mix.max-media(var.$screen-md){
        right: 17px;
      }
    }

    &.is-open:after {
      width: 20px;
      height: 20px;
      background: url("../images/page-faq/icon-close.svg") center center no-repeat;
      background-size: cover;
      right: 20px;
      @include mix.max-media(var.$screen-md){
        width: 14px;
        height: 14px;
        right: 15px;
      }
    }
  }

  &__body {
    padding: 0 42px;
    max-height: 0;
    color: var.$brand-main-darker;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.6;
    opacity: 0;
    position: relative;
    @include mix.transition-delay();

    @include mix.max-media(var.$screen-md){
      padding: 0 40px 0 31px;
      font-size: 14px;
    }

    &.is-open {
      margin-top: 25px;
      padding: 16px 42px 15px;
      opacity: 1;
      max-height: var(--max-height);
      pointer-events: auto;
      @include mix.max-media(var.$screen-md){
        margin-top: 20px;
        padding: 8px 40px 8px 31px;
      }
    }

    &:before {
      content: "A";
      width: 55px;
      height: 55px;
      border: 1px var.$brand-main solid;
      border-radius: 50%;
      color: rgba(0, 0, 0, 0);
      -webkit-text-stroke: 1px var.$brand-main;
      font-family: var.$font-family-roboto;
      font-size: 31px;
      line-height: 1;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: -27px;

      @include mix.max-media(var.$screen-md){
        width: 42px;
        height: 42px;
        font-size: 24px;
        left: -23px;
      }
    }

    &:after{
      content: "";
      display: block;
      width: 1px;
      height: calc(100%);
      background: var.$brand-main;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0,-100%);
      z-index: -1;
      @include mix.max-media(var.$screen-md){
        left: -1px;
      }
    }
  }
}