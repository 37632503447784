@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ header
// ============================================================

.header {
  width: 100%;
  padding: 40px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var.$zindex-header;
  background-color: inherit;
  @include mix.transition-delay();

  @include mix.max-media(var.$screen-md){
    padding: 20px 0;
  }

  .is-changeHeader & {
    padding: 20px 0;
    background-color: var.$brand-main;
  }

  &__inner {
    padding: 0 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @include mix.max-media(var.$screen-md){
      padding: 0 20px;
    }


    .is-changeHeader & {
      align-items: center;
    }
  }
}

//  @ h-logo
// ----------------------------------------

.h-logo {
  margin: 0 30px 0 0;
  width: 100%;
  max-width: 223px;
  @include mix.max-media(var.$screen-md){
    max-width: 160px;
  }

  .is-changeHeader & {
    max-width: 180px;
    @include mix.max-media(var.$screen-md){
      max-width: 160px;
    }
  }

  a {
    display: block;
    @include mix.text-hover();
  }
}