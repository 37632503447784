@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
//  @ breadcrumb
// ============================================================

.breadcrumb {
  margin: 30px 0;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar { /* Chrome, Safari 対応 */
    display: none;
  }

  @include mix.max-media(var.$screen-md) {
    margin: 20px 0;
  }

  ul {
    color: var.$brand-main-darker;
    font-size: 12px;
    letter-spacing: .05em;
    display: flex;
    flex-flow: row nowrap;

    li {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      white-space: nowrap;

      &:not(:last-child) {

        &:after {
          margin: 0 15px;
          content: "";
          display: block;
          width: 4px;
          height: 8px;
          background: url("../images/common/breadcrumb-icon-arrow.svg") center center no-repeat;
          background-size: cover;
        }
      }

      a {
        color: #5998D6;
        text-decoration: none;
      }
    }
  }
}