@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.ie_alert{
  position: fixed;
  z-index: var.$ie_alert-layer;
  bottom:0;
  left: 0;
  right: 0;
  background: #cc0000;

  &__inner{
    padding: 30px;
  }
  
  &__title{
    margin: 0 auto 1em;
    text-align: center;
    font-size: 18px;
    color: #fff;
  }
  
  &__content{
    color: #fff;
    text-align: center;
  }
}

